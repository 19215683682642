import { ReactComponent as DinersClubIcon } from 'assets/cards/Diners_Club.svg';
import { ReactComponent as JCBIcon } from 'assets/cards/JCB.svg';
import { ReactComponent as UnionPayIcon } from 'assets/cards/UnionPay.svg';
import { ReactComponent as AmexIcon } from 'assets/cards/amex-2.svg';
import { ReactComponent as DiscoverIcon } from 'assets/cards/discover.svg';
import { ReactComponent as MastercardIcon } from 'assets/cards/mastercard.svg';
import { ReactComponent as VisaIcon } from 'assets/cards/visa.svg';

import * as s from './styles';
import { CardType } from './types';

const dots = [0, 1, 2];

const Card = ({ isSelected = false, brand, last4, expMonth, expYear, className = '', handleClick }: CardType) => (
  <s.Wrapper
    className={className}
    isSelected={isSelected}
    onClick={handleClick}
    data-testid={`order-detail-card-${last4}`}
  >
    {brand?.toLowerCase().includes('visa') && (
      <s.Visa>
        <VisaIcon />
      </s.Visa>
    )}
    {brand?.toLowerCase().includes('mastercard') && (
      <s.MasterCard>
        <MastercardIcon />
      </s.MasterCard>
    )}
    {(brand === 'amex' || brand === 'American Express') && (
      <s.Amex>
        <AmexIcon />
      </s.Amex>
    )}
    {brand?.toLowerCase().includes('diners') && (
      <s.Diners>
        <DinersClubIcon />
      </s.Diners>
    )}
    {brand?.toLowerCase().includes('discover') && (
      <s.Discover>
        <DiscoverIcon />
      </s.Discover>
    )}
    {brand?.toLowerCase().includes('jcb') && (
      <s.JCB>
        <JCBIcon />
      </s.JCB>
    )}
    {brand?.toLowerCase().includes('unionpay') && (
      <s.UnionPay>
        <UnionPayIcon />
      </s.UnionPay>
    )}
    <s.Expiry>
      {expMonth.toString().padStart(2, '0')}/{expYear.toString().slice(-2)}
    </s.Expiry>
    {dots.map((dot) => (
      <s.Dots key={dot} index={dot}>
        ....
      </s.Dots>
    ))}
    <s.Last4>{last4}</s.Last4>
  </s.Wrapper>
);

export default Card;
