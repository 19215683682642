import RadioButtonGroup from 'Components/RadioButtonGroup/';
import { RadioType } from 'Components/RadioButtonGroup/types';
import { ChangeEvent, useEffect, useState } from 'react';
import { toCurrency } from 'utils/currency';

import { getDescription } from './PayPortionUtils';
import * as s from './SelectInstalment.styles';
import { PaymentOptionType, SelectInstalmentType } from './types';

const SelectInstalment = ({ payout, setPayAmount, paymentOption, setPaymentOption }: SelectInstalmentType) => {
  const [description, setDescription] = useState<string>(() => getDescription(payout, PaymentOptionType.NextPayment));
  const [paymentItems, setPaymentItems] = useState<RadioType[]>([]);
  useEffect(() => {
    setDescription(() => getDescription(payout, paymentOption));
  }, [paymentOption, payout]);

  useEffect(() => {
    let payAmount = 0;
    if (paymentOption === PaymentOptionType.NextPayment) {
      payAmount = payout.schedule[0].amount;
    } else if (paymentOption === PaymentOptionType.PayAll) {
      payAmount = payout.planAmountOutstanding ?? 0;
    }
    setPayAmount(payAmount);
  }, [paymentOption, payout, setPayAmount]);

  useEffect(() => {
    if (payout.schedule.length > 1) {
      const obj1 = {
        value: PaymentOptionType.NextPayment,
        text: 'Next payment',
        amount: toCurrency(payout.schedule?.[0]?.amount ?? 0, payout.currency),
      };
      const obj2 = {
        value: PaymentOptionType.PayAll,
        text: 'Pay outstanding balance',
        amount: toCurrency(payout.planAmountOutstanding ?? 0, payout.currency),
      };
      setPaymentItems([{ ...obj1 }, { ...obj2 }]);
    }
  }, [payout.schedule, payout.planAmountOutstanding, payout.currency]);

  useEffect(() => {
    if (payout.schedule.length < 2) {
      const obj = {
        value: PaymentOptionType.PayAll,
        text: 'Pay outstanding balance',
        amount: toCurrency(payout.planAmountOutstanding ?? 0, payout.currency),
      };
      setPaymentItems([{ ...obj }]);
      setPaymentOption(PaymentOptionType.PayAll);
    }
  }, [payout.planAmountOutstanding, payout.schedule.length, setPaymentOption, payout.currency]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPaymentOption(e.target.value as PaymentOptionType);
  };

  return (
    <s.Wrapper>
      <s.OptionWrapper>
        <s.Title>Make a payment</s.Title>
        <s.TitleHr />
        {paymentItems.length > 0 && (
          <RadioButtonGroup
            handleChange={handleChange}
            value={paymentOption}
            background="#F8F8FB"
            name="selectInstalment"
            radios={paymentItems}
          />
        )}
        <s.Description data-testid="payment-description" dangerouslySetInnerHTML={{ __html: description }} />
      </s.OptionWrapper>
    </s.Wrapper>
  );
};

export default SelectInstalment;
