import 'react-circular-progressbar/dist/styles.css';

import Alert from 'Components/Alert/Alert';
import EmptyList from 'Components/EmptyList/EmptyList';
import Pagination from 'Components/Pagination/Pagination';
import Spinner from 'Components/Spinner/Spinner';
import { ReactComponent as ArrowDownIcon } from 'assets/svg/arrow-down.svg';
import { ReactComponent as FlagAltIcon } from 'assets/svg/flag-alt.svg';
import { ReactComponent as RepeatIcon } from 'assets/svg/repeat-icon.svg';
import React, { MouseEvent, ReactElement, useCallback, useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import { setActivePayment } from 'redux/Actions';
import { ReduxStateType } from 'redux/Constants/types';
import { toCurrency } from 'utils/currency';
import getDateFormat from 'utils/getDateFormat';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';

import { ListHeaders } from './Constants';
import { SpinnerWrapper } from './styles';
import { APIResponseType, ParamsType } from './types';
import { showPaymentStatus } from './utils';

export type PaymentsProps = {
  pageCount: number;
  setPageCount(p: number): void;
  activePage: number;
  setActivePage(p: number): void;
  pageStartIndex: number;
  setPageStartIndex(p: number): void;
};

const Payments = ({
  pageCount,
  setPageCount,
  activePage,
  setActivePage,
  pageStartIndex,
  setPageStartIndex,
}: PaymentsProps): ReactElement => {
  const [payoutList, setPayoutList] = useState<APIResponseType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const params: ParamsType = useParams();

  const { apiBaseUri, customerId, activeTabIndex } = useSelector((state: ReduxStateType) => ({
    apiBaseUri: state.apiBaseUri,
    customerId: state.customerId,
    activeTabIndex: state.paymentsTabId,
  }));

  const dispatch: Dispatch<any> = useDispatch();
  const setActivePaymentCB = useCallback((s, a) => dispatch(setActivePayment(s, a)), [dispatch]);

  useEffect(() => {
    if (!apiBaseUri || !customerId || params.id) {
      return;
    }

    let isMounted = true;

    const fetchData = async () => {
      setIsLoading(true);
      let stateOptions = '';
      switch (activeTabIndex) {
        case 0:
          stateOptions = 'payPlanState=Active&payPlanState=Completed&payPlanState=InDefault';
          break;
        case 1:
          stateOptions = 'payPlanState=Active';
          break;
        case 2:
          stateOptions = 'payPlanState=Completed';
          break;
        case 3:
          stateOptions = 'payPlanState=InDefault';
          break;
      }
      const url = `${apiBaseUri}/dashboard/customer/${customerId}/payplans?sort=-createdAt&page=${activePage}&${stateOptions}`;
      const options = await getFetchOptions();
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return {
            pages: Number(res.headers.get('Limepay-Page-Count')),
            response: await res.json(),
          };
        })
        .then(({ pages, response }) => {
          if (isMounted) {
            setPayoutList(response);
            setPageCount(pages);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          if (isMounted) {
            setErrorMsg(e.message || 'Failed to fetch payments');
            setIsLoading(false);
          }
        });
    };
    fetchData();

    return () => {
      isMounted = false;
    };
  }, [activePage, apiBaseUri, customerId, activeTabIndex, setPageCount, params.id]);

  const toggleDetailsView = (e: MouseEvent<HTMLLIElement>, transactionId: string): void => {
    e.preventDefault();
    const payment = payoutList.find((item) => item.transactionId === transactionId);
    setActivePaymentCB(true, payment);
    window.scrollTo({ top: 0 });
  };

  return (
    <div className="payments-page">
      <article className="content reset-lr">
        <div className="lp-box">
          <div className="card items">
            <ul className="item-list striped">
              <li className="item item-list-header">
                <div className="item-row">
                  {ListHeaders.map((header, index) => (
                    <div key={index} className={`item-col item-col-header ${header.className}`}>
                      <span>{header.text}</span>
                    </div>
                  ))}
                </div>
              </li>
              {isLoading && (
                <SpinnerWrapper>
                  <Spinner color="#0016D1" />
                </SpinnerWrapper>
              )}
              {errorMsg.length > 0 && <Alert message={errorMsg} />}
              {!isLoading && errorMsg.length === 0 && payoutList.length === 0 && <EmptyList />}
              {!isLoading &&
                errorMsg.length === 0 &&
                payoutList.length > 0 &&
                payoutList.map((payout) => (
                  <li
                    className="item active-item"
                    key={payout.transactionId}
                    onClick={(e) => toggleDetailsView(e, payout.transactionId)}
                    data-testid="payment-order-item"
                  >
                    <div className="item-row item-row-reset-m">
                      <div className="item-col item-col-img md">
                        <CircularProgressbar
                          value={payout.planCompletionPercent}
                          styles={buildStyles({
                            pathColor: payout.planCompletionPercent < 100 ? '#3e98c7' : '#81d275',
                          })}
                        />
                        {payout.planCompletionPercent < 100 && <RepeatIcon className="repeat-icon" />}
                        {payout.planCompletionPercent === 100 && <FlagAltIcon className="flag-alt-icon" />}
                      </div>
                      <div className="item-col item-col-sales custom-col-xsrr">
                        <div className="media">
                          <div className="order-id custom-order-id">
                            <h4 className="price item-title">{payout.merchantOrderDetails.internalOrderId}</h4>
                            <div className="card-end-text">
                              {getDateFormat({ time: payout.payPlanCreatedAt, showFullYear: true }).date}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item-col custom-col-xsr">
                        <div className="item text-left mt-1">
                          <h4 className="price item-title">
                            {payout.planAmount ? toCurrency(payout.planAmount, payout.currency) : '-'}
                          </h4>
                        </div>
                      </div>
                      <div className="item-col custom-col-xsr">
                        <div className="item text-left mt-1">
                          <h4 className="price item-title">Instalments</h4>
                          {!!payout.paymentSource && (
                            <div className="card-end-text">
                              {payout.paymentSource.brand} ending x-{payout.paymentSource.last4}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="item-col custom-col-xsr">
                        <div className="item text-left mt-1">
                          <h4 className="price item-title">
                            {payout.paidToDate !== null ? toCurrency(payout.paidToDate, payout.currency) : '-'}
                          </h4>
                        </div>
                      </div>
                      <div className="item-col custom-col-xsr">
                        <div className="item text-left mt-1">
                          <h4 className="price item-title">
                            {payout.planAmountOutstanding !== null
                              ? toCurrency(payout.planAmountOutstanding, payout.currency)
                              : '-'}
                          </h4>
                        </div>
                      </div>
                      <div className="item-col custom-col-xsr">
                        <div className="item text-left mt-1">
                          <h4 className="price item-title">{showPaymentStatus(payout)}</h4>
                        </div>
                      </div>
                      <div className="item-col fixed item-col-actions-dropdown">
                        <div className="item-actions-dropdown">
                          <ArrowDownIcon className="arrow-right-icon" />
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </article>
      <Pagination
        pageCount={pageCount}
        activePage={activePage}
        setActivePage={setActivePage}
        pageStartIndex={pageStartIndex}
        setPageStartIndex={setPageStartIndex}
      />
    </div>
  );
};

export default Payments;
