import styled from '@emotion/styled';

export const Content = styled.article`
  padding: 30px 22px 75px 22px;
  display: block;
  @media (min-width: 1200px) {
    padding: 18px 60px 75px 60px;
  }
  @media (max-width: 768px) {
    padding: 15px 16px 75px;
    position: relative;
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
`;

type HeaderStatusType = {
  color: string;
};
export const HeaderStatus = styled.div<HeaderStatusType>`
  border-radius: 16px;
  padding: 4px 8px;
  background: ${(props) => props.color};
  height: 28px;
  margin-top: 33px;
  font-weight: 700;
  @media (max-width: 960px) {
    margin-top: 22px;
  }
  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

export const Wrapper = styled.div`
  @media (max-width: 960px) {
    .header-title.lp-dashboard-top,
    .back-component.lp-dashboard-top {
      padding-left: 22px;
    }
  }
  @media (max-width: 768px) {
    .header-title.lp-dashboard-top {
      padding-left: 22px;
      padding-top: 0;
      background-color: #f8f8fb;
    }
  }

  .back-component.lp-dashboard-top {
    padding-bottom: 0;
  }
`;
