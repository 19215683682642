import styled from '@emotion/styled';

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StateOptions = styled.div`
  padding: 30px 22px 10px;
  @media (min-width: 1200px) {
    padding: 30px 60px 10px;
  }
  @media (max-width: 768px) {
    padding: 10px 20px 10px;
  }
`;

export const Wrapper = styled.div`
  background-color: #f8f8fb;
  min-height: 100vh;
`;

export const DetailsSpinner = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MessageBar = styled.div`
  margin-top: 15px;
  padding: 0 20px;
  @media (min-width: 1200px) {
    padding: 0 60px;
  }
  @media (max-width: 768px) {
    padding: 0;
    margin-bottom: 20px;
  }
  .payment-not-found {
    margin-bottom: 0;
  }
`;
