import { APIResponseType } from 'Components/Payments/types';
import { MouseEvent } from 'react';

export type PayoutProps = {
  payout: APIResponseType;
  isOverdue: boolean;
  attemptsCount: number[];
};

export type CardType = {
  cardPaymentSourceId: string;
  last4: string;
  brand: string;
  expMonth: number;
  expYear: number;
};

export interface PayOffResponse {
  totalAmountDue: number;
}

export type PayoutInfoProps = {
  payout: APIResponseType;
  toggleModalMakePayment(e: MouseEvent<HTMLButtonElement>): void;
  attemptsCount: number[];
};

export type PayPlanDetailsType = {
  payout: APIResponseType;
  toggleModalEditPayment(e: MouseEvent<HTMLButtonElement>): void;
  isOverdue: boolean;
};

export type TransactionHistoryType = {
  payout: APIResponseType;
};

export type TransactionType = {
  time: string;
  balance: number;
  description: string;
  creditAmount: number | null;
  debitAmount: number | null;
};

export type OverdueInfoType = {
  date: string;
};

export type OverdueBoxType = {
  payout: APIResponseType;
  attemptsCount: number[];
  toggleModalEditPayment(e: MouseEvent<HTMLButtonElement>): void;
};

export type PayPortionType = {
  payout: APIResponseType;
};

export enum ModalStepType {
  SelectInstalment = 'SelectInstalment',
  SelectPayment = 'SelectPayment',
  Success = 'Success',
}

export type SelectInstalmentType = {
  payout: APIResponseType;
  setPayAmount(a: number): void;
  paymentOption: PaymentOptionType;
  setPaymentOption(p: PaymentOptionType): void;
};

export type PaymentScheduleType = {
  title: string;
  status: string;
  amount: string;
  paidDate: string;
  hasDescription: boolean;
  isActive: boolean;
  cardInfo: string;
};

export enum PaymentOptionType {
  NextPayment = 'NextPayment',
  PayAll = 'PayAll',
  CustomAmount = 'CustomAmount',
}

export type SelectPaymentType = {
  payout: APIResponseType;
};

export interface PayPortionResponse {
  payPlanId: string;
  totalAmountDue: number;
  nextInstalmentDate: string | null;
}

export type ParamsType = {
  merchantName: string;
  id: string | undefined;
  pay: string | undefined;
};
