const compareTwoStrings = (a: string, b: string): boolean => {
  if (a.length !== b.length) {
    return false;
  }

  let result: number = 0;
  for (let i = 0; i < a.length; i++) {
    result |= +a[i] ^ +b[i];
  }
  return result === 0;
};

export default compareTwoStrings;
