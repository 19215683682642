const MILLISECONDS_PER_DAY = 86_400_000;

const showDueSoon = (date: string): boolean => {
  const time = new Date(date).getTime();
  const now = Date.now();

  if (time < now) {
    return false;
  }
  return Math.floor((time - now) / MILLISECONDS_PER_DAY) < 2;
};

export default showDueSoon;
