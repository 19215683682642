import React, { ReactElement } from 'react';

import { Child, Wrapper } from './Spinner.style';

export type SpinnerType = {
  width?: string;
  height?: string;
  color?: string;
  borderWidth?: string;
};

const array = [0, 1, 2, 3];

const Spinner = ({
  width = '40px',
  height = '40px',
  color = '#fff',
  borderWidth = '4px',
}: SpinnerType): ReactElement => (
  <Wrapper style={{ width, height }}>
    {array.map((item) => (
      <Child key={item} borderWidth={borderWidth} style={{ borderColor: `${color} transparent transparent` }} />
    ))}
  </Wrapper>
);

export default Spinner;
