import { firebaseApp } from 'APIs/firebase';

interface FirebaseUserClaims {
  user_id: string;
  email: string;
  firebase: {
    tenant: string;
  };
  limepay: {
    customerId: string;
  };
}

export interface User {
  claims: FirebaseUserClaims;
}

const getCurrentUser = async (): Promise<User | null> => {
  const currentUser = firebaseApp.auth().currentUser;

  if (!currentUser) return null;

  const { claims } = await currentUser.getIdTokenResult();

  return {
    claims: claims as FirebaseUserClaims,
  };
};

export default getCurrentUser;
