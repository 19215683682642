import React, { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { setCustomerInfo } from 'redux/Actions/';
import { ReduxStateType } from 'redux/Constants/types';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import { identifyZendeskUser } from 'utils/zendesk';

import { defaultBranding } from './Constants';
import Sidebar from './Sidebar';

const SidebarContainer = (): ReactElement => {
  const { customerId, apiBaseUri, firstName, lastName, initials, branding, email } = useSelector(
    (state: ReduxStateType) => ({
      customerId: state.customerId,
      apiBaseUri: state.apiBaseUri,
      firstName: state.firstName,
      lastName: state.lastName,
      initials: state.initials,
      branding: state.branding,
      email: state.email,
    }),
  );

  const dispatch: Dispatch<any> = useDispatch();

  const setCustomerInfoCB = useCallback((e, p, f, l) => dispatch(setCustomerInfo(e, p, f, l)), [dispatch]);

  useEffect(() => {
    if (!apiBaseUri || !customerId) {
      return;
    }

    const fetchData = async () => {
      const url = `${apiBaseUri}/customers/${customerId}`;
      const options = await getFetchOptions();
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return res.json();
        })
        .then(({ email, phoneNumber, firstName, lastName }) => {
          setCustomerInfoCB(email, phoneNumber, firstName, lastName);
          identifyZendeskUser(`${firstName} ${lastName}`, email);
        })
        .catch((e) => {
          console.error(e.message || 'Failed to get customer info');
        });
    };
    fetchData();
  }, [apiBaseUri, customerId, setCustomerInfoCB]);

  return (
    <Sidebar
      name={firstName || lastName || ''}
      email={email || ''}
      initials={initials}
      branding={branding ?? defaultBranding}
    />
  );
};

export default SidebarContainer;
