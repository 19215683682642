import styled from '@emotion/styled';

interface LoginBtnProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading: boolean;
}

export const LoginBtn = styled.button<LoginBtnProps>`
  cursor: pointer;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  vertical-align: middle;

  border-radius: 4px;
  font-size: 16px;
  width: 100%;

  padding: 13px 38px 10px 38px;
  color: #fff;
  background-color: var(--lp-colors-medium-blue-600);
  border: 1px solid var(--lp-colors-medium-blue-600);
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled {
    background-color: ${(props) => (props.isLoading ? 'var(--lp-colors-medium-blue-600)' : '#f8f8fb')};
    border: ${(props) => (props.isLoading ? 'var(--lp-colors-medium-blue-600)' : '#f8f8fb')};
    opacity: ${(props) => (props.isLoading ? '0.6' : '1')};
    color: #b3b8cc;
    cursor: default;
    & > * > * {
      fill: #b3b8cc;
    }
  }
`;

export const Wrapper = styled('div')`
  min-height: 100vh;
  padding-top: 80px;
  color: #555c7c;
  background-color: #f6f6fa;

  .otp-auth-box {
    margin: 0 auto;
    display: block;
    max-width: 412px;
    border-radius: 4px;
    padding: 24px;
    background-color: #ffffff;
  }

  .box-title {
    font-size: 20px;
    line-height: 24px;
    margin: 0;
    color: #191e33;
    font-family: 'Geograph', Arial, sans-serif;
  }

  .inputs {
    & > * {
      margin-bottom: 24px;
    }
  }
  .input-group {
    display: flex;
    align-content: stretch;
    & > button {
      border: none;
      border-bottom: 1px solid #e0e1ea;
      color: var(--lp-colors-medium-blue-600);
      background-color: transparent;
      font-size: 10px;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
      &:active,
      &:focus {
        border: none;
        border-bottom: 1px solid #e0e1ea;
        outline: none;
      }
    }
  }
  .remember {
    align-items: center;
    font-size: 14px;
    cursor: pointer;
  }

  .form-control {
    flex: 1 0 auto;
    font-size: 16px;
    padding: 16px 0px;
    border: none;
    border-bottom: 1px solid #e0e1ea;
    margin: 0;
    -webkit-appearance: none;
    border-radius: 0;
    &:active,
    &:focus {
      border: none;
      border-bottom: 1px solid #e0e1ea;
      outline: none;
    }
  }

  .input-group-addon {
    border-bottom: 1px solid #e0e1ea;
    display: flex;
    padding: 16px;
    align-items: center;
  }

  .box-footer {
    flex: 1 0 auto;
  }

  .arrow-right {
    width: 20px;
    height: 20px;
    margin-left: 12px;
  }
  .check-mark {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #b3b8cc;
    margin-right: 8px;
    &:hover {
      cursor: pointer;
    }
  }
  .go-back {
    flex: 1 0 auto;
    text-align: center;
    cursor: pointer;
    color: var(--lp-colors-medium-blue-600);
  }

  .alert {
    font-size: 12px;
    padding: 16px 12px;
    margin-bottom: 16px;
    color: #b32d3f;
    background-color: #fdf5f6;
    border: 1px solid #d3354a;
    border-radius: 4px;
  }

  .alert-success {
    color: #3c763d;
    background-color: #dff0d8;
    border: 1px solid #d6e9c6;
  }

  @media (max-width: 768px) {
    padding-top: 30px;

    .otp-auth-box {
      padding: 12px;
    }
  }

  .lpResendCodes {
    color: var(--lp-colors-medium-blue-600);
    font-weight: 300;
    text-decoration: underline;
  }
  .lpResendCodes:hover {
    color: var(--lp-colors-medium-blue-600);
  }
`;
