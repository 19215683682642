import { logout } from 'APIs/identity';
import { ReactComponent as SidebarLogo } from 'assets/svg/april-sidebar-logo.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/svg/arrow-down.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import React, { MouseEvent, ReactElement, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { NavLink, useHistory } from 'react-router-dom';
import { BrandingType } from 'redux/Constants/types';
import { ReduxStateType } from 'redux/Constants/types';
import getMerchantName from 'utils/getMerchantName';
import useClickOutside from 'utils/useClickOutside';

import { getSideBarLinks } from './Constants';
import {
  Aside,
  Brand,
  Card,
  CloseBtn,
  Collapse,
  CollapseBtn,
  Container,
  DropdownItemBtn,
  FirstName,
  Footer,
  Header,
  IconBar,
  ImgText,
  MenuLi,
  MenuUl,
  NavLinkBtn,
  ProfileDropdownMenu,
  Wrapper,
} from './styles';
import { TabType } from './types';

export type SidebarProps = {
  initials: string;
  name: string;
  email: string;
  branding: BrandingType;
};

const Sidebar = ({ initials, name, email, branding }: SidebarProps): ReactElement => {
  const [showAccordion, setShowAccordion] = useState<boolean>(false);
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  const [merchantName] = useState<string>(() => getMerchantName());

  const history = useHistory();

  const isDesktop = useMediaQuery({ query: '(min-width: 961px)' });
  const ref = useRef<HTMLDivElement>(null);

  const showPaymentDetailsPage = useSelector((state: ReduxStateType) => state.showPaymentDetailsPage);

  const handleClickOutside = (): void => {
    if (!isDesktop) {
      setOpenSidebar(false);
    }
  };

  useClickOutside(ref, handleClickOutside, []);

  const toggleAccordion = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowAccordion((show) => !show);
  };

  const handleSignOut = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    logout()
      .then(() => {
        window.location.href = `/${merchantName}/login`;
      })
      .catch(() => {
        console.error('error to logout');
      });
  };

  const handleShowSidebar = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setOpenSidebar(true);
  };

  const handleHideSidebar = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setOpenSidebar(false);
  };

  const handleClickOption = (e: MouseEvent<HTMLAnchorElement>, tab: string): void => {
    e.preventDefault();
    history.push(tab);
    !isDesktop && setOpenSidebar(false);
  };

  return (
    <Wrapper ref={ref}>
      {!showPaymentDetailsPage && (
        <CollapseBtn onClick={handleShowSidebar}>
          <IconBar />
          <IconBar />
          <IconBar />
        </CollapseBtn>
      )}
      <Aside open={openSidebar}>
        <Container>
          <Header>
            <Brand>
              <NavLink
                to={`/${merchantName}/${TabType.Purchases}`}
                onClick={(e) => handleClickOption(e, TabType.Purchases)}
              >
                {branding.logoUri && (
                  <img src={branding.logoUri} alt={branding.logoAltText || 'logo'} className="sidebar-logo" />
                )}
                {!branding.logoUri && <SidebarLogo className="sidebar-logo" />}
              </NavLink>
            </Brand>
            <CloseBtn onClick={handleHideSidebar}>
              <CloseIcon className="close-icon" />
            </CloseBtn>
          </Header>
          <nav className="menu">
            <MenuUl>
              {getSideBarLinks(merchantName).map((link, index) => (
                <MenuLi key={index}>
                  <NavLink
                    to={link.href}
                    className="menu-link"
                    activeClassName="selected"
                    onClick={(e) => handleClickOption(e, link.href)}
                  >
                    {link.label}
                  </NavLink>
                </MenuLi>
              ))}
            </MenuUl>
          </nav>
        </Container>
        <Footer open={openSidebar}>
          {initials && (
            <div className="pb-2">
              <ImgText>{initials}</ImgText>
            </div>
          )}

          <Card>
            <NavLinkBtn aria-expanded={showAccordion} aria-controls="collapseOne" onClick={toggleAccordion}>
              <FirstName data-testid="sidebarFirstName">{name && name !== '-' ? name : email}</FirstName>
              <ArrowDownIcon className={`arrow-down ${showAccordion ? 'up' : ''}`} />
            </NavLinkBtn>
            <Collapse show={showAccordion}>
              <ProfileDropdownMenu>
                <DropdownItemBtn onClick={handleSignOut}>Sign out</DropdownItemBtn>
              </ProfileDropdownMenu>
            </Collapse>
          </Card>
        </Footer>
      </Aside>
    </Wrapper>
  );
};

export default Sidebar;
