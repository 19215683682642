import InfoModal from 'Components/InfoModal';
import { COMPLETED } from 'Components/Payments/Constants';
import { LpDetails, Row } from 'Constants/styles';
import { MouseEvent, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { toCurrency } from 'utils/currency';
import getDateFormat from 'utils/getDateFormat';
import useClickOutside from 'utils/useClickOutside';

import {
  BalanceText,
  CreditsModal,
  DebitsModal,
  FortnightText,
  GreyColumn,
  GreyContent,
  GreyRow,
  Hr,
  InfoIcon,
  InfoWrapper,
  LargeText,
  OrderTextFlex,
  OrderTextValue,
  PayAllBtn,
  PayPlanText,
  Symbol,
  Text4x,
} from './PayoutInfo.styles';
import { getTotalCredits, getTotalDebits } from './getTotalFees';
import { Box, Flex, SectionHeader, Text } from './styles';
import { PayoutInfoProps } from './types';

const PayoutInfo = ({ payout, toggleModalMakePayment, attemptsCount }: PayoutInfoProps): ReactElement => {
  const [showCreditsModal, setShowCreditsModal] = useState<boolean>(false);
  const [showDebitsModal, setShowDebitsModal] = useState<boolean>(false);
  const [isOverdue, setIsOverdue] = useState<boolean>(attemptsCount.length > 2 && attemptsCount[2] > 2);

  const creditsRef = useRef<HTMLDivElement>(null);
  const debitsRef = useRef<HTMLDivElement>(null);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const tooltipMobile = useMediaQuery({ query: '(max-width: 450px)' });

  const instalmentFrequency = payout.instalmentPlan.instalmentFrequency;

  const instalmentFrequencyLabel = useMemo(
    () =>
      instalmentFrequency === 'Weekly'
        ? 'weekly'
        : instalmentFrequency === 'Fortnightly'
        ? 'fortnightly'
        : instalmentFrequency === 'EveryThirtyDays'
        ? 'monthly'
        : null,
    [instalmentFrequency],
  );

  useEffect(() => {
    setIsOverdue(attemptsCount.length > 2 && attemptsCount[2] > 2);
  }, [attemptsCount]);

  const toggleCreditsModal = (e: MouseEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setShowCreditsModal(true);
  };

  const toggleDebitsModal = (e: MouseEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setShowDebitsModal(true);
  };

  const handleClickOutsideCredits = (): void => {
    setShowCreditsModal(false);
  };

  const handleClickOutsideDebits = (): void => {
    setShowDebitsModal(false);
  };

  useClickOutside(creditsRef, handleClickOutsideCredits, []);
  useClickOutside(debitsRef, handleClickOutsideDebits, []);

  return (
    <Box>
      <LpDetails>
        <SectionHeader>Order details</SectionHeader>
        <Row className="mt-4">
          <div className="col-sm-12 col-md-8">
            <OrderTextFlex>
              <Text>Order date</Text>

              <OrderTextValue>
                <Text className="text-md-left text-right">
                  {getDateFormat({ time: payout.payPlanCreatedAt, showFullYear: true }).date}
                </Text>
              </OrderTextValue>
            </OrderTextFlex>
            <OrderTextFlex className="mt-2">
              <div>
                <Text>Payment method</Text>
              </div>
              <OrderTextValue>
                <PayPlanText>
                  <Text4x className="text-md-left text-right">Pay plan</Text4x>
                  {!!instalmentFrequencyLabel && !isMobile && (
                    <FortnightText className="text-right">- {instalmentFrequencyLabel} payments</FortnightText>
                  )}
                </PayPlanText>
                {!!instalmentFrequencyLabel && isMobile && (
                  <FortnightText className="text-right">{instalmentFrequencyLabel} payments</FortnightText>
                )}
              </OrderTextValue>
            </OrderTextFlex>
          </div>
          <div className="col-sm-12 col-md-4 mt-4 mt-md-0 text-center">
            {payout.state !== COMPLETED && (
              <PayAllBtn overdue={isOverdue} onClick={toggleModalMakePayment} data-testid="button-order-pay">
                {isOverdue ? 'Pay overdue balance' : 'Make a payment'}
              </PayAllBtn>
            )}
          </div>
        </Row>
        <Row className="mt-4">
          <div className="col-12">
            <GreyContent>
              <GreyRow>
                <GreyColumn width="18%">
                  <Flex sm="center" color="#555C7C">
                    <Text>Order total</Text>
                  </Flex>
                  <LargeText className="mt-2" color="#191E33" data-testid="order-detail-ordertotal">
                    {toCurrency(payout.planAmount ?? 0, payout.currency)}
                  </LargeText>
                </GreyColumn>

                <GreyColumn>
                  <Text>
                    <Flex sm="center" color="#555C7C">
                      {!isMobile && <Symbol>-</Symbol>}
                      Money In
                      <InfoWrapper ref={creditsRef}>
                        <InfoIcon
                          onMouseLeave={() => setShowCreditsModal(false)}
                          onMouseEnter={() => setShowCreditsModal(true)}
                          onClick={toggleCreditsModal}
                        >
                          i
                        </InfoIcon>
                        {showCreditsModal && (
                          <CreditsModal>
                            <InfoModal
                              isMobile={tooltipMobile}
                              content="May include scheduled and manual payments, refunds, account credits or waivers"
                            />
                          </CreditsModal>
                        )}
                      </InfoWrapper>
                    </Flex>
                  </Text>
                  <LargeText className="mt-2" color="#191E33" left="40px" data-testid="order-detail-moneyid">
                    {toCurrency(getTotalCredits(payout), payout.currency)}
                  </LargeText>
                </GreyColumn>
                <Hr />
                <GreyColumn>
                  <Text>
                    <Flex sm="center" color="#555C7C">
                      {!isMobile && <Symbol>+</Symbol>}
                      Money out
                      <InfoWrapper ref={debitsRef}>
                        <InfoIcon
                          onMouseLeave={() => setShowDebitsModal(false)}
                          onMouseEnter={() => setShowDebitsModal(true)}
                          onClick={toggleDebitsModal}
                        >
                          i
                        </InfoIcon>
                        {showDebitsModal && (
                          <DebitsModal>
                            <InfoModal content="May include late fees you have accrued for missed payments" />
                          </DebitsModal>
                        )}
                      </InfoWrapper>
                    </Flex>
                  </Text>
                  <LargeText className="mt-2" left="40px" color="#191E33" data-testid="order-detail-moneyout">
                    {toCurrency(getTotalDebits(payout), payout.currency)}
                  </LargeText>
                </GreyColumn>

                <GreyColumn width="32%">
                  <Text>
                    <Flex sm="center" color="#555C7C">
                      {!isMobile && (
                        <>
                          <Symbol>=</Symbol>
                          {isOverdue ? 'Overdue balance' : 'Outstanding balance'}
                        </>
                      )}
                      {isMobile && <>Balance</>}
                    </Flex>
                  </Text>
                  <BalanceText className="mt-2" data-testid="order-detail-balance">
                    {toCurrency(payout.planAmountOutstanding ?? 0, payout.currency)}
                  </BalanceText>
                </GreyColumn>
              </GreyRow>
            </GreyContent>
          </div>
        </Row>
      </LpDetails>
    </Box>
  );
};

export default PayoutInfo;
