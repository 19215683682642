const formatVgsError = (error: string): string => {
  if (error.includes('required')) {
    return 'Please complete this required field';
  } else if (error.includes('card number')) {
    return 'Please enter a valid card number';
  } else if (error.includes('expiration')) {
    return 'Please enter a valid expiry date';
  } else if (error.includes('security')) {
    return 'Please enter a valid CVC';
  }
  return error;
};

export default formatVgsError;
