import calculateAttemptsCount from 'Components/PurchaseDetails/calculateAttemptsCount';
import checkIsOverdue from 'Components/PurchaseDetails/checkIsOverdue';

import { APIResponseType } from './types';

export const showPaymentStatus = (payout: APIResponseType) => {
  if (payout.state === 'Completed') {
    return 'Paid';
  }
  const counts = calculateAttemptsCount(payout);
  // when 4th instalment has 3 failed attempts, schedule array will be empty
  const isOverdue = checkIsOverdue(payout) || counts[2] > 2;
  if (payout.state === 'Active') {
    return isOverdue ? 'Overdue' : 'Active';
  }

  return payout.state;
};
