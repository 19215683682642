import './css/global.css';

import { firebaseApp } from 'APIs/firebase';
import { signInWithCustomToken } from 'APIs/identity';
import { B2BApprove } from 'Components/B2B/B2BApprove';
import Message from 'Components/Message';
import Login from 'Components/OTPLogin/OTPLogin';
import Payments from 'Components/Payments';
import Settings from 'Components/Settings/Settings';
import Sidebar from 'Components/Sidebar';
import Spinner from 'Components/Spinner/Spinner';
import Support from 'Components/Support';
import qs from 'query-string';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';
import { setConfig, setCustomerId, updateLoggedIn } from 'redux/Actions/';
import { ReduxStateType } from 'redux/Constants/types';
import compareTwoStrings from 'utils/compareTwoStrings';
import getCurrentUser from 'utils/getCurrentUser';
import getMerchantName from 'utils/getMerchantName';
import handleApiError from 'utils/handleApiError';
import { clearZendeskWidget, configZendeskWidget } from 'utils/zendesk';

import { SpinnerWrapper, Wrapper } from './App.styles';
import PrivateRoute from './PrivateRoute';

interface ValidateTokenInterface {
  tokenState: 'valid' | 'invalid' | 'expired';
  emailAddress: string | null;
  customToken: string | null;
}

const App = (): ReactElement => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const dispatch: Dispatch<any> = useDispatch();
  const { isLoggedIn, isLoginPending, apiBaseUri, branding, tenantId } = useSelector((state: ReduxStateType) => ({
    isLoggedIn: state.isLoggedIn,
    isLoginPending: state.isLoginPending,
    apiBaseUri: state.apiBaseUri,
    customerId: state.customerId,
    branding: state.branding,
    tenantId: state.tenantId,
  }));
  const [merchantName] = useState(() => getMerchantName());
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const { ref } = qs.parse(window.location.search);

  const setCustomerIdCB = useCallback((cId: string) => dispatch(setCustomerId(cId)), [dispatch]);
  const updateLoggedInCB = useCallback((isLoggedIn: boolean) => dispatch(updateLoggedIn(isLoggedIn)), [dispatch]);
  const setConfigCB = useCallback(
    (apiBaseUri, tenantId, merchantId, branding, tcUrl, merchantTags) =>
      dispatch(setConfig(apiBaseUri, tenantId, merchantId, branding, tcUrl, merchantTags)),
    [dispatch],
  );

  useEffect(() => {
    const initialize = async () => {
      try {
        const { headers } = await fetch('/');
        const apiHost = headers.get('api-ingress-host') || '';
        const response = await fetch(`${apiHost}/config/customer?m=${merchantName}`);
        if (!response.ok) {
          await handleApiError(response);
        }

        const { authApiKey, authDomain, apiBaseUri, tenantId, merchantId, branding, tcUrl, merchantTags } =
          await response.json();

        setConfigCB(apiBaseUri, tenantId, merchantId, branding, tcUrl, merchantTags);
        firebaseApp
          .initializeApp({ apiKey: authApiKey, authDomain })
          .auth()
          .onAuthStateChanged(async () => {
            const currentUser = await getCurrentUser();
            const firebaseTenantId = currentUser?.claims.firebase.tenant ?? '';
            const isMatched = compareTwoStrings(tenantId, firebaseTenantId);
            updateLoggedInCB(isMatched && !!currentUser);
            if (isMatched && !!currentUser) {
              setCustomerIdCB(currentUser.claims.limepay.customerId);
              configZendeskWidget();
            } else {
              clearZendeskWidget();
            }
          });
      } catch (error) {
        setErrorMessage(error?.message || 'Failed to fetch config');
      }
    };
    initialize();
  }, [merchantName, setConfigCB, setCustomerIdCB, updateLoggedInCB]);

  useEffect(() => {
    if (!branding || !branding.iconUri) {
      return;
    }
    const favicon = document.getElementById('customer-dashboard-favicon') as HTMLLinkElement;
    if (favicon) {
      favicon.href = branding.iconUri;
    }
  }, [branding]);

  useEffect(() => {
    if (!ref || !apiBaseUri || !merchantName || isLoggedIn) {
      return;
    }

    const loginWithToken = async () => {
      try {
        setLoading(true);
        const url = `${apiBaseUri}/authn/exchange-auth-token`;

        const options = {
          method: 'POST',
          headers: {
            'Limepay-Token': ref as string,
            'Content-Type': 'application/json',
          },
        };

        const res = await fetch(url, options);
        if (!res.ok) {
          await handleApiError(res);
        }
        const json: ValidateTokenInterface = await res.json();
        setErrorMsg('');
        switch (json.tokenState) {
          case 'valid':
            await signInWithCustomToken(tenantId, json.customToken ?? '', false);
            setLoading(false);
            break;

          case 'invalid':
            window.location.href = `/${merchantName}/login`;
            break;

          case 'expired':
            const { origin, pathname } = window.location;
            const encodedPath = encodeURIComponent(origin + pathname);
            const encodedEmail = encodeURIComponent(json.emailAddress ?? '');
            window.location.href = `/${merchantName}/login?email=${encodedEmail}&path=${encodedPath}`;
            break;
        }
      } catch (e) {
        setErrorMsg(e?.message || 'Failed to validate token status');
        setLoading(false);
      }
    };

    loginWithToken();
  }, [apiBaseUri, isLoggedIn, merchantName, ref, tenantId]);

  return (
    <Wrapper noSideBar={!isLoggedIn || isLoginPending}>
      {errorMsg.length > 0 && <Message success={false} title="Request failed" description={errorMessage} />}
      {loading && errorMsg.length === 0 && (
        <SpinnerWrapper>
          <Spinner color="#0016D1" />
        </SpinnerWrapper>
      )}
      <BrowserRouter>
        {!isLoginPending && isLoggedIn && <Sidebar />}
        <Switch>
          <Route path="/:merchantName/login" component={Login} />
          <Route path="/:merchantName/approve" component={B2BApprove} />
          {isLoggedIn !== null && (
            <>
              <PrivateRoute
                token={ref as string}
                isLoggedIn={isLoggedIn}
                path="/:merchantName/purchases/:id?/:pay?"
                component={Payments}
              />
              <PrivateRoute exact isLoggedIn={isLoggedIn} path="/:merchantName/settings" component={Settings} />
              <PrivateRoute exact isLoggedIn={isLoggedIn} path="/:merchantName/support" component={Support} />
            </>
          )}
          <Route
            path="/:merchantName"
            exact
            render={(props) => <Redirect to={`/${merchantName}/purchases/${props.location.search}`} {...props} />}
          />
        </Switch>
      </BrowserRouter>
    </Wrapper>
  );
};

export default App;
