import styled from '@emotion/styled';

export const ModalTitle = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #191e33;
  margin-top: 34px;
`;

export const Hr = styled.div`
  height: 1px;
  margin: 22px 0 24px;
  background-color: #e0e1ea;
`;

export const SubTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #555c7c;
  margin: 0 32px 16px;
  @media (max-width: 492px) {
    margin: 0 0 16px;
  }
`;

export const CardListSpinner = styled.div`
  height: 104px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NoneCarousel = styled.div`
  display: flex;
  margin: 0 32px;

  .left {
    margin-left: 13px;
    &:first-of-type {
      margin-left: 0;
    }
  }
  @media (max-width: 492px) {
    margin: 0;
  }
  @media (max-width: 380px) {
    flex-direction: column;
    .left {
      margin-left: 0;
      margin-top: 10px;
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
`;

export const CarouselWrapper = styled.div`
  display: flex;
  margin: 0 -9px 0 32px;

  .carousel-root {
    width: 100%;
  }

  .carousel .control-dots {
    display: none;
  }

  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    opacity: 1;
  }

  .carousel .control-prev.control-arrow {
    background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 543.75%);
    &:before {
      border-right: 8px solid #555c7c;
    }
  }

  .carousel .control-next.control-arrow {
    background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 543.75%);
    &:before {
      border-left: 8px solid #555c7c;
    }
  }

  @media (max-width: 492px) {
    margin-left: 0;
  }
`;

export const CardWrapper = styled.div<{ show: boolean }>`
  padding: 0 32px;
  display: ${({ show }) => (show ? 'block' : 'none')};
  @media (max-width: 492px) {
    padding: 0;
    margin-bottom: 24px;
  }
`;

export const CardNumber = styled.div`
  position: relative;
  padding: 8px 16px 8px 56px;
  height: 56px;
  border-bottom: 1px solid #e0e1ea;

  .card-number-icon {
    position: absolute;
    left: 18px;
    top: 18px;
  }
`;

export const StripeInput = styled.div`
  height: 40px;
  width: 100%;
  color: #191e33;
  iframe {
    width: 100%;
    height: 100%;
  }
`;

export const Expiry = styled.div`
  position: relative;
  padding: 8px 16px 8px 56px;
  height: 56px;
  border-bottom: 1px solid #e0e1ea;
  width: 45%;
  .expiry-icon {
    position: absolute;
    left: 18px;
    top: 15px;
  }
`;

export const Cvc = styled.div`
  position: relative;
  padding: 8px 16px 8px 56px;
  height: 56px;
  border-bottom: 1px solid #e0e1ea;
  width: 45%;
  .cvc-icon {
    position: absolute;
    left: 18px;
    top: 18px;
  }
`;

export const StripeRow = styled.div`
  display: inline-flex;
  justify-content: space-between;
  margin: auto;
`;

type StripeErrorMsgType = {
  hasError: boolean;
};

export const StripeErrorMsg = styled.div<StripeErrorMsgType>`
  font-size: 11px;
  line-height: 1.1;
  font-weight: 300;
  padding-top: 10px;
  color: rgb(184, 0, 10);
  position: absolute;
  left: 0;
  display: ${(props) => (props.hasError ? 'block' : 'none')};
`;

export const SelectPaymentWrapper = styled.div`
  padding: 0 24px 80px;
  width: 450px;
  @media (max-width: 492px) {
    width: 100%;
    padding-bottom: 0;
  }
`;

export const SubTitleWithLink = styled.button`
  background-color: transparent;
  padding: 0;
  outline: none;
  border: none;
  margin: 24px 32px 16px;
  color: #555c7c;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  cursor: pointer;
  text-decoration: underline;
  display: flex;
  align-items: center;
  height: 20px;
  @media (max-width: 492px) {
    margin: 24px 0 16px;
  }

  .arrow-down {
    width: 20px;
    fill: #555c7c;
    transition: transform 0.3s ease-in-out 0s;
    margin-left: auto;

    &.up {
      transform: rotate(180deg);
    }
  }
`;
