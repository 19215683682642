import styled from '@emotion/styled';

const LpNotice = styled.div`
  height: 50vh;
  justify-content: center;
  display: flex;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 24px;
  line-height: 26px;
  font-weight: bold;
  margin-bottom: 0;
  color: #354052;
  font-family: 'Geograph', Arial, sans-serif;
`;

const EmptyList = () => (
  <LpNotice data-testid="lp-notice">
    <Title data-testid="title">No results found</Title>
  </LpNotice>
);

export default EmptyList;
