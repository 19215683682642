import './Payments.scss';

import HeaderTitle from 'Components/HeaderTitle/HeaderTitle';
import Message from 'Components/Message';
import PurchaseDetails from 'Components/PurchaseDetails/PurchaseDetails';
import Spinner from 'Components/Spinner/Spinner';
import React, { MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import { setPaymentsTabId } from 'redux/Actions';
import { setActivePayment } from 'redux/Actions/';
import { ReduxStateType } from 'redux/Constants/types';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';

import { PaymentsTabs } from './Constants';
import Payments from './Payments';
import { DetailsSpinner, MessageBar, StateOptions, Wrapper } from './styles';
import { ParamsType } from './types';

const PaymentsContainer = () => {
  const [pageCount, setPageCount] = useState<number>(1);
  const [activePage, setActivePage] = useState<number>(1);
  const [pageStartIndex, setPageStartIndex] = useState<number>(1);

  const [detailsLoading, setDetailsLoading] = useState<boolean>(false);
  const [detailsMsg, setDetailsMsg] = useState<string>('');

  const { showPaymentDetailsPage, activeTabIndex, apiBaseUri, customerId } = useSelector((state: ReduxStateType) => ({
    showPaymentDetailsPage: state.showPaymentDetailsPage,
    activeTabIndex: state.paymentsTabId,
    apiBaseUri: state.apiBaseUri,
    customerId: state.customerId,
  }));

  const params: ParamsType = useParams();
  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    if (!params.id || !customerId) {
      return;
    }
    const fetchWithPayPlanId = async () => {
      try {
        setDetailsLoading(true);
        const url = `${apiBaseUri}/dashboard/customer/${customerId}/payplans/${params.id}`;
        const options = await getFetchOptions();
        const res = await fetch(url, options);
        if (!res.ok) {
          await handleApiError(res);
        }
        const json = await res.json();
        setDetailsMsg('');
        dispatch(setActivePayment(true, json));
      } catch (e) {
        setDetailsMsg(e.message || 'Failed to fetch pay plan.');
      } finally {
        setDetailsLoading(false);
      }
    };
    fetchWithPayPlanId();
  }, [apiBaseUri, customerId, dispatch, params.id]);

  useEffect(() => {
    document.title = 'Purchases - April';
  }, []);

  const handleSwitchState = (e: MouseEvent<HTMLLIElement>, index: number): void => {
    e.preventDefault();
    dispatch(setPaymentsTabId(index));
    setActivePage(1);
  };

  const handleCloseMsg = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setDetailsMsg('');
  };

  if (detailsLoading) {
    return (
      <DetailsSpinner>
        <Spinner color="#0016D1" />
      </DetailsSpinner>
    );
  }

  return (
    <Wrapper className="payments-tab">
      {!showPaymentDetailsPage && (
        <>
          <HeaderTitle title="Purchases" />
          <StateOptions>
            <ul role="tablist" className="nav">
              {PaymentsTabs.map((tab, index) => (
                <li
                  key={index}
                  className="nav-item"
                  onClick={(e) => handleSwitchState(e, index)}
                  data-testid={`nav-item-${tab.text.toLowerCase()}`}
                >
                  <div
                    className={`nav-link ${activeTabIndex === index ? 'active' : ''}`}
                    role="tab"
                    aria-selected={activeTabIndex === index}
                  >
                    {tab.text}
                  </div>
                </li>
              ))}
            </ul>
          </StateOptions>
          {detailsMsg.length > 0 && (
            <MessageBar>
              <Message
                success={false}
                title="Error: payment not found"
                description={detailsMsg}
                handleClose={handleCloseMsg}
                className="payment-not-found"
              />
            </MessageBar>
          )}
          <Payments
            pageCount={pageCount}
            setPageCount={setPageCount}
            activePage={activePage}
            setActivePage={setActivePage}
            pageStartIndex={pageStartIndex}
            setPageStartIndex={setPageStartIndex}
          />
        </>
      )}
      {showPaymentDetailsPage && <PurchaseDetails />}
    </Wrapper>
  );
};

export default PaymentsContainer;
