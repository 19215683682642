import styled from '@emotion/styled';

export const LpBox = styled.div`
  background-color: #fff;
  padding: 31px 29px 28px 29px;
  border: 1px #e7e7e7 solid;
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.5);
  margin-bottom: 20px;
  border-radius: 4px;

  @media (max-width: 768px) {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
  }
`;

export const LpDetails = styled.div`
  color: #000;
  font-weight: 500;
`;

export const Content = styled.article`
  padding: 30px 22px 75px 22px;
  display: block;
  @media (min-width: 1200px) {
    padding: 18px 60px 75px 60px;
  }
  @media (max-width: 768px) {
    padding: 0 0 160px 0;
    position: relative;
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }
`;

export const H2 = styled.h2`
  margin-bottom: 8px;
  margin-top: 0;
  font-family: 'Geograph', Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000;
`;

export const H3 = styled.h3`
  margin: 0;
  font-weight: 500;
  font-family: 'Geograph', Arial, sans-serif;
  font-size: 24px;
  line-height: 28px;
  color: #354052;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;

export const Label = styled.label`
  margin-bottom: 8px;
  cursor: default;
  display: inline-block;
  font-weight: 300;
  color: #6c7488;
  font-size: 15px;
`;
