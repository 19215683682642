import { VgsCardError } from 'redux/Constants/vgsAndStripeTypes';

import { VaultFieldStyles, VaultValidationResult } from '@april/lib-ui';

import { getAprilHost } from './env';

export const getVaultHost = async () => `https://vault.${await getAprilHost()}`;

export const fieldStyles: VaultFieldStyles = {
  fontSize: '12.5px',
  '&:focus': {
    color: '#000000',
  },
  '&::placeholder': {
    color: '#8C95A8',
  },
  '&:focus::placeholder': {
    color: '#8C95A8',
  },
  '&.isInvalid': { color: '#D3354A' },
  '&.isValid': { color: '#4F8A10' },
};

export const toCardError = (errors: VaultValidationResult['errors']): VgsCardError => ({
  formError: 'Invalid card',
  cardNumberError: errors.cardNumber,
  expiryDateError: errors.expiryDate,
  cardCvcError: errors.cardCvc,
});
