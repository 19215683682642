import styled from '@emotion/styled';

export const Wrapper = styled.div<{ noSideBar: boolean }>`
  padding-left: ${({ noSideBar }) => (noSideBar ? '0' : '240px')};
  position: relative;
  width: 100%;
  min-height: 100vh;

  @media (max-width: 960px) {
    padding-left: 0;
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;
