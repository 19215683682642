import React, { ReactElement } from 'react';

import { H2, LabelText } from './styles';

const Notifications = (): ReactElement => (
  <div className="lp-box notifications-wrapper">
    <div className="row">
      <div className="col-sm-7">
        <H2>Notifications</H2>
      </div>
    </div>
    <div className="lp-details">
      <div className="row">
        <div className="col-sm-12">
          <LabelText>We’ll always email you when you make a purchase or payment.</LabelText>
        </div>
      </div>
    </div>
  </div>
);

export default Notifications;
