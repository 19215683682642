import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding: 0 22px 20px;

  @media (min-width: 1200px) {
    padding: 0 60px 20px;
  }
`;

export const Ul = styled.ul`
  display: flex;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 16px;
  list-style: none;
  border-radius: 4px;

  @media (max-width: 768px) {
    padding-left: 20px;
  }
`;

export const Li = styled.li`
  list-style: none;
  margin: 0 5px 0 0;
  padding: 0;
`;

export const PageLink = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  font-size: 14px;
  line-height: 24px;
  text-decoration: underline;
  width: 24px;
  height: 24px;
  text-align: center;
  color: #555c7c;
  margin-right: 4px;

  &:hover {
    color: var(--lp-colors-medium-blue-600);
    text-decoration: none;
  }

  &.active {
    cursor: default;
    text-decoration: none;
    background: #ffffff;
    box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.08);
    border-radius: 4px;

    &:hover {
      color: #555c7c;
    }
  }
`;
