import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { ConsumerCustomerCardSource, VAULT_CARD, vaultCreatePaymentSource } from 'APIs/vault';
import { changePayPlanCardPaymentSource, payAllOfPayPlan } from 'APIs/vgs';
import Card from 'Components/Card';
import Checkbox from 'Components/Checkbox';
import Message from 'Components/Message/';
import ModalV2 from 'Components/ModalV2/';
import { COMPLETED } from 'Components/Payments/Constants';
import Spinner from 'Components/Spinner/Spinner';
import { ReactComponent as ArrowDownIcon } from 'assets/svg/arrow-down.svg';
import { ReactComponent as CardNumberIcon } from 'assets/svg/card-number.svg';
import { ReactComponent as CVCIcon } from 'assets/svg/cvc.svg';
import { ReactComponent as ExpiryIcon } from 'assets/svg/expiry.svg';
import React, { MouseEvent, ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Carousel } from 'react-responsive-carousel';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import { setActivePayment, setPaymentsTabId } from 'redux/Actions';
import { ReduxStateType } from 'redux/Constants/types';
import { toCurrency } from 'utils/currency';
import formatVgsError from 'utils/formatVgsError';
import getCurrentUserToken from 'utils/getCurrentUserToken';
import getFetchOptions from 'utils/getFetchOptions';
import handleApiError from 'utils/handleApiError';
import setBodyOverflow from 'utils/setBodyOverflow';
import { fieldStyles, getVaultHost } from 'utils/vault';
import { hideZendeskWidget, showZendeskWidget } from 'utils/zendesk';

import { Vault, createVault } from '@april/lib-ui';

import { cardCvcId, cardExpiryId, cardNumberId } from './Constants';
import * as s from './OrderDetails.styles';
import OverdueBox from './OverdueBox';
import PayPlanDetails from './PayPlanDetails';
import PaymentSuccess from './PaymentSuccess';
import PayoutInfo from './PayoutInfo';
import SelectInstalment from './SelectInstalment';
import TransactionHistory from './TransactionHistory';
import {
  CardType,
  ModalStepType,
  ParamsType,
  PayOffResponse,
  PayPortionResponse,
  PaymentOptionType,
  PayoutProps,
} from './types';

const OrderDetails = ({ payout, isOverdue, attemptsCount }: PayoutProps): ReactElement => {
  /** 3ds states */
  const [vaultCard, setVaultCard] = useState<Vault>();
  const [cardNumberError, setCardNumberError] = useState<string>('');
  const [expiryDateError, setExpiryDateError] = useState<string>('');
  const [cardCvcError, setCardCvcError] = useState<string>('');

  /** make payment states */
  const [showModalMakePayment, setShowModalMakePayment] = useState<boolean>(false);
  const [modalStep, setModalStep] = useState<ModalStepType>(ModalStepType.SelectInstalment);
  const [makePaymentCancelText, setMakePaymentCancelText] = useState<string>('Cancel');
  const [makePaymentReturnText, setMakePaymentReturnText] = useState<string>('');
  const [makePaymentConfirmText, setMakePaymentConfirmText] = useState<string>('Select payment source');
  // amount to pay, depending on paymentOption
  const [payAmount, setPayAmount] = useState<number>(0);
  // select pay next instalment or pay all
  const [paymentOption, setPaymentOption] = useState<PaymentOptionType>(
    payout.schedule.length > 1 ? PaymentOptionType.NextPayment : PaymentOptionType.PayAll,
  );
  // save card for future use
  const [cardSaved, setCardSaved] = useState<boolean>(true);
  const [showCardInput, setShowCardInput] = useState<boolean>(false);

  /** edit payment states */
  const [showModalEditPayment, setShowModalEditPayment] = useState<boolean>(false);
  const [showEditPaymentMsg, setShowEditPaymentMsg] = useState<boolean>(false);

  /** common states */
  const [paymentCards, setPaymentCards] = useState<CardType[]>([]);
  const [activePaymentSource, setActivePaymentSource] = useState<string>('');
  const [unconfirmedActivePaymentSource, setUnconfirmedActivePaymentSource] = useState<string>('');
  const [msgTitle, setMsgTitle] = useState<string>('');
  const [msgDesc, setMsgDesc] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cardListLoading, setCardListLoading] = useState<boolean>(false);
  const [existCardSelected, setExistCardSelected] = useState<boolean>(true);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0);
  const [isCardFormReady, setIsCardFormReady] = useState<boolean>(false);

  /** deep link states */
  const [loadModalOnMount, setLoadModalOnMount] = useState<boolean>(false);
  const params: ParamsType = useParams();

  const dispatch: Dispatch<any> = useDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 492px)' });

  const { customerId, apiBaseUri, merchantTags } = useSelector((state: ReduxStateType) => ({
    customerId: state.customerId,
    apiBaseUri: state.apiBaseUri,
    merchantTags: state.merchantTags,
  }));

  useEffect(() => {
    setActivePaymentSource(payout.paymentSource?.cardPaymentSourceId ?? '');
    setUnconfirmedActivePaymentSource(payout.paymentSource?.cardPaymentSourceId ?? '');
  }, [payout]);

  useEffect(() => {
    const index = paymentCards.findIndex((c) => c.cardPaymentSourceId === activePaymentSource);
    setSelectedItemIndex(index < 0 ? 0 : index);
  }, [paymentCards, activePaymentSource]);

  useEffect(() => {
    /** if the plan is paid, should not launch modal */
    if (!params.id || !params.pay || !payout || payout.state === COMPLETED || loadModalOnMount === true) {
      return;
    }

    hideZendeskWidget();

    setCardNumberError('');
    setCardCvcError('');
    setExpiryDateError('');

    setBodyOverflow('hidden');
    setIsLoading(false);
    setMsgTitle('');
    setMsgDesc('');
    setUnconfirmedActivePaymentSource(payout.paymentSource?.cardPaymentSourceId ?? '');
    setExistCardSelected(true);
    setIsCardFormReady(false);

    setShowModalMakePayment(true);
    const shouldShowStep1 = payout.schedule.length > 0;
    setModalStep(shouldShowStep1 ? ModalStepType.SelectInstalment : ModalStepType.SelectPayment);
    setMakePaymentCancelText('Cancel');
    setMakePaymentReturnText('');
    const confirm = shouldShowStep1
      ? 'Select payment source'
      : `Pay ${toCurrency(payout.planAmountOutstanding ?? 0, payout.currency)} now`;
    setMakePaymentConfirmText(confirm);
    setCardSaved(true);
    setLoadModalOnMount(true);
    if (!shouldShowStep1) {
      const fetchCards = async () => {
        const url = `${apiBaseUri}/customers/${customerId}/payments/sources/card`;

        const options = await getFetchOptions();
        setCardListLoading(true);
        fetch(url, options)
          .then(async (res) => {
            if (!res.ok) {
              await handleApiError(res);
            }
            return res.json();
          })
          .then((response) => {
            setPaymentCards(response);
          })
          .catch((e) => {
            setMsgTitle(e.message);
          })
          .finally(() => {
            setCardListLoading(false);
          });
      };
      if (!apiBaseUri || !customerId) {
        return;
      }
      fetchCards();
    }
  }, [apiBaseUri, customerId, loadModalOnMount, params.id, params.pay, payout]);

  const loadVaultCard = useCallback(async () => {
    setIsCardFormReady(false);
    setExistCardSelected(true);

    const vault = await createVault({
      vaultHost: await getVaultHost(),
      fields: {
        cardNumber: { elementId: cardNumberId, fieldStyles },
        expiryDate: { elementId: cardExpiryId, fieldStyles },
        cardCvc: { elementId: cardCvcId, fieldStyles },
      },
      onReady: () => setIsCardFormReady(true),
      onEvent: ({ eventName, field, vault }) => {
        if (eventName === 'Change') {
          const { cardNumber, expiryDate, cardCvc } = vault.fields;
          const isEmpty = cardNumber?.state.isEmpty && expiryDate?.state.isEmpty && cardCvc?.state.isEmpty;

          if (field.type === 'cardNumber') {
            setCardNumberError('');
          }
          if (field.type === 'expiryDate') {
            setExpiryDateError('');
          }
          if (field.type === 'cardCvc') {
            setCardCvcError('');
          }

          setExistCardSelected(isEmpty ?? true);
        }
      },
    });
    setVaultCard(vault);
  }, []);

  useEffect(() => {
    (async () => {
      if (showModalEditPayment || (showModalMakePayment && modalStep === ModalStepType.SelectPayment)) {
        await loadVaultCard();
      }
    })();
  }, [loadVaultCard, merchantTags, modalStep, showModalEditPayment, showModalMakePayment]);

  useEffect(() => {
    if (!showEditPaymentMsg) {
      return;
    }

    const timer = setTimeout(() => {
      setShowEditPaymentMsg(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, [showEditPaymentMsg]);

  const fetchWithPayPlanId = async () => {
    const url = `${apiBaseUri}/dashboard/customer/${customerId}/payplans/${payout.payPlanId}`;
    const options = await getFetchOptions();
    const res = await fetch(url, options);
    if (!res.ok) {
      await handleApiError(res);
    }
    const json = await res.json();
    dispatch(setActivePayment(true, json));
  };

  const handleConfirmEditPayment = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (existCardSelected) {
        await handleSubmitEditPayment(unconfirmedActivePaymentSource);
      } else {
        const response = await handleSubmitNewCard();
        if (response) {
          await handleSubmitEditPayment(response.ConsumerCustomerCardSource.paymentSourceId);
        }
      }
      await fetchWithPayPlanId();
      window.scrollTo({ top: 0 });
      setBodyOverflow('auto');
      setShowModalEditPayment(false);
      setShowEditPaymentMsg(true);
      showZendeskWidget();
    } catch (e) {
      setMsgTitle('Failed to update payment');
      e?.message && setMsgDesc(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitEditPayment = async (source: string): Promise<void> => {
    const token = await getCurrentUserToken();
    await changePayPlanCardPaymentSource(apiBaseUri, token, customerId, payout.payPlanId, source);
  };

  const handleSubmitPayOff = async (): Promise<void> => {
    const token = await getCurrentUserToken();
    const payOffResponse: PayOffResponse = await payAllOfPayPlan(apiBaseUri, token, customerId, payout.payPlanId);
    if (payOffResponse.totalAmountDue > 0) {
      throw Error();
    }
  };

  const handleSubmitNewCard = useCallback(async () => {
    if (!vaultCard) throw Error();

    const token = await getCurrentUserToken();
    const { cardError, response } = await vaultCreatePaymentSource<ConsumerCustomerCardSource>(vaultCard, token, {
      CreateConsumerCardSource: {
        ...VAULT_CARD,
      },
    });

    if (cardError || !response) {
      setMsgTitle('Failed to update payment');
      setMsgDesc(cardError?.formError || 'Failed to add a new card');
      throw Error();
    }

    return response;
  }, [vaultCard]);

  const fetchListOfCards = async () => {
    const url = `${apiBaseUri}/customers/${customerId}/payments/sources/card`;

    const options = await getFetchOptions();
    setCardListLoading(true);
    fetch(url, options)
      .then(async (res) => {
        if (!res.ok) {
          await handleApiError(res);
        }
        return res.json();
      })
      .then((response) => {
        setPaymentCards(response);
      })
      .catch((e) => {
        setMsgTitle(e.message);
      })
      .finally(() => {
        setCardListLoading(false);
      });
  };

  const reset3dsStates = () => {
    setCardNumberError('');
    setCardCvcError('');
    setExpiryDateError('');
  };

  const resetCommonStates = () => {
    setBodyOverflow('auto');
    setIsLoading(false);
    setMsgTitle('');
    setMsgDesc('');
    setUnconfirmedActivePaymentSource(activePaymentSource);
    setExistCardSelected(true);
    setIsCardFormReady(false);
    setShowCardInput(false);
  };

  const resetMakePaymentStates = () => {
    setShowModalMakePayment(false);
    const shouldShowStep1 = payout.schedule.length > 0;
    setModalStep(shouldShowStep1 ? ModalStepType.SelectInstalment : ModalStepType.SelectPayment);
    setMakePaymentCancelText('Cancel');
    setMakePaymentReturnText('');
    const confirm = shouldShowStep1
      ? 'Select payment source'
      : `Pay ${toCurrency(payout.planAmountOutstanding ?? 0, payout.currency)} now`;
    setMakePaymentConfirmText(confirm);
    setCardSaved(true);
  };

  const resetEditPaymentStates = () => {
    setShowModalEditPayment(false);
  };

  const handleCancelEditPayment = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    resetCommonStates();
    reset3dsStates();
    resetEditPaymentStates();
    showZendeskWidget();
  };

  const handleCancelMakePayment = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    resetCommonStates();
    reset3dsStates();
    resetMakePaymentStates();
    showZendeskWidget();
  };

  const toggleModalMakePayment = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    hideZendeskWidget();
    reset3dsStates();
    resetCommonStates();
    resetMakePaymentStates();
    setBodyOverflow('hidden');
    setShowModalMakePayment(true);
    if (payout.schedule.length === 0) {
      fetchListOfCards();
    }
  };

  const toggleModalEditPayment = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    hideZendeskWidget();
    reset3dsStates();
    resetCommonStates();
    resetEditPaymentStates();
    setBodyOverflow('hidden');
    setShowModalEditPayment(true);
    fetchListOfCards();
  };

  const handleCloseMessage = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setMsgTitle('');
  };

  const payPortionRequest = async (): Promise<PayPortionResponse> => {
    const url = `${apiBaseUri}/customers/${customerId}/payments/plan/${payout.payPlanId}/pay-portion`;
    const body = {
      paymentAmount: {
        amount: payAmount,
        currency: payout.currency,
      },
      numberOfInstalmentsToPay: paymentOption === PaymentOptionType.NextPayment ? 1 : payout.schedule.length,
    };
    const options = await getFetchOptions('POST', JSON.stringify(body));

    const res = await fetch(url, options);
    if (!res.ok) {
      await handleApiError(res);
    }
    return await res.json();
  };

  const handleMakePaymentStep1 = () => {
    setModalStep(ModalStepType.SelectPayment);
    setMakePaymentCancelText('');
    setMakePaymentReturnText('Back');
    setMakePaymentConfirmText(`Pay ${toCurrency(payAmount, payout.currency)} now`);
    setCardSaved(true);
    setShowCardInput(false);
    fetchListOfCards();
  };

  const handleMakePaymentStep2 = async () => {
    try {
      setIsLoading(true);
      // when it is overdue, must pay all the outstanding
      const mustPayAll = payout.schedule.length === 0;
      if (existCardSelected) {
        if (activePaymentSource !== unconfirmedActivePaymentSource) {
          await handleSubmitEditPayment(unconfirmedActivePaymentSource);
        }
        if (mustPayAll) {
          await handleSubmitPayOff();
        } else {
          const payPortionResponse = await payPortionRequest();
          // if totalAmountDue is not changed, even code is 200, it should throw error
          if (payout.planAmountOutstanding === payPortionResponse.totalAmountDue) {
            throw Error();
          }
        }
      } else {
        const response = await handleSubmitNewCard();
        if (response) {
          await handleSubmitEditPayment(response.ConsumerCustomerCardSource.paymentSourceId);
        }
        if (paymentOption === PaymentOptionType.NextPayment && !mustPayAll) {
          const payPortionResponse = await payPortionRequest();
          if (payout.planAmountOutstanding === payPortionResponse.totalAmountDue) {
            throw Error();
          }
        } else {
          await handleSubmitPayOff();
        }
        // if card not saved for remaining payments, set previous card as default payment
        if (!cardSaved) {
          await handleSubmitEditPayment(activePaymentSource);
        }
      }
      dispatch(setPaymentsTabId(0));
      setMakePaymentCancelText('');
      setMakePaymentReturnText('');
      setMakePaymentConfirmText('');
      setModalStep(ModalStepType.Success);
    } catch (e) {
      setMsgTitle('Payment failed');
      setMsgDesc(
        e?.message ||
          'Your manual payment was declined. Please make sure you have available funds or select another card and try again.',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmMakePayment = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();

    if (modalStep === ModalStepType.SelectInstalment) {
      handleMakePaymentStep1();
    }
    if (modalStep === ModalStepType.SelectPayment) {
      try {
        await handleMakePaymentStep2();
        await fetchWithPayPlanId();
      } catch (e) {
        setMsgTitle('Payment failed');
        setMsgDesc(e.message);
      }
    }
  };

  const handleReturnMakePayment = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setModalStep(ModalStepType.SelectInstalment);
    setMakePaymentCancelText('Cancel');
    setMakePaymentReturnText('');
    setMakePaymentConfirmText('Select payment source');
  };

  const handleChangeSaveCard = (): void => {
    setCardSaved((s) => !s);
  };

  const handleClickCarousel = (index: number) => {
    setExistCardSelected(true);
    setUnconfirmedActivePaymentSource(paymentCards[index].cardPaymentSourceId);
  };

  const handleClickNoneCarousel = (e: MouseEvent<HTMLDivElement>, source: string): void => {
    e.preventDefault();
    setExistCardSelected(true);
    setUnconfirmedActivePaymentSource(source);
  };

  const toggleCardInput = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowCardInput((show) => !show);
  };

  const CardInput = (show: boolean) => (
    <s.CardWrapper show={show}>
      <s.CardNumber>
        <CardNumberIcon className="card-number-icon" />
        <s.StripeInput id={cardNumberId} data-testid="order-detail-newsource-card-number" />
        <s.StripeErrorMsg hasError={!!cardNumberError}>{formatVgsError(cardNumberError)}</s.StripeErrorMsg>
      </s.CardNumber>

      <s.StripeRow className="mt-3">
        <s.Expiry>
          <ExpiryIcon className="expiry-icon" />
          <s.StripeInput id={cardExpiryId} data-testid="order-detail-newsource-card-expiry" />
          <s.StripeErrorMsg hasError={!!expiryDateError}>{formatVgsError(expiryDateError)}</s.StripeErrorMsg>
        </s.Expiry>
        <s.Cvc>
          <CVCIcon className="cvc-icon" />
          <s.StripeInput id={cardCvcId} data-testid="order-detail-newsource-card-cvc" />
          <s.StripeErrorMsg hasError={!!cardCvcError}>{formatVgsError(cardCvcError)}</s.StripeErrorMsg>
        </s.Cvc>
      </s.StripeRow>
      {showModalMakePayment && paymentOption === PaymentOptionType.NextPayment && payout.schedule.length > 1 && (
        <Checkbox
          className="mt-4"
          checked={cardSaved}
          text="Charge remaining payments for this plan to this card"
          handleChange={handleChangeSaveCard}
        />
      )}
    </s.CardWrapper>
  );

  const SelectPaymentView = (title: string, alwaysShowCardInput: boolean) => (
    <s.SelectPaymentWrapper>
      <s.ModalTitle>{title}</s.ModalTitle>
      <s.Hr />
      {msgTitle.length > 0 && (
        <Message success={false} title={msgTitle} description={msgDesc} handleClose={handleCloseMessage} />
      )}
      <s.SubTitle>Select a saved payment source</s.SubTitle>
      {cardListLoading && (
        <s.CardListSpinner>
          <Spinner color="#0016D1" />
        </s.CardListSpinner>
      )}
      {!cardListLoading && paymentCards.length > 2 && (
        <s.CarouselWrapper>
          <Carousel
            centerMode
            centerSlidePercentage={isMobile ? 50 : 47}
            showStatus={false}
            showThumbs={false}
            onClickItem={handleClickCarousel}
            selectedItem={selectedItemIndex}
          >
            {paymentCards.map((card) => (
              <Card
                key={card.cardPaymentSourceId}
                className={`${unconfirmedActivePaymentSource === card.cardPaymentSourceId ? 'isSelected' : ''}`}
                isSelected={unconfirmedActivePaymentSource === card.cardPaymentSourceId}
                {...card}
              />
            ))}
          </Carousel>
        </s.CarouselWrapper>
      )}
      {!cardListLoading && paymentCards.length < 3 && (
        <s.NoneCarousel>
          {paymentCards.map((card) => (
            <Card
              key={card.cardPaymentSourceId}
              handleClick={(e) => handleClickNoneCarousel(e, card.cardPaymentSourceId)}
              className={`left ${unconfirmedActivePaymentSource === card.cardPaymentSourceId ? 'isSelected' : ''}`}
              isSelected={unconfirmedActivePaymentSource === card.cardPaymentSourceId}
              {...card}
            />
          ))}
        </s.NoneCarousel>
      )}
      {!alwaysShowCardInput && (
        <>
          <s.SubTitleWithLink onClick={toggleCardInput} data-testid="toggleCardInput">
            <div>Or add a new payment source</div>
            <ArrowDownIcon className={`arrow-down ${showCardInput ? 'up' : ''}`} />
          </s.SubTitleWithLink>
          {CardInput(showCardInput)}
        </>
      )}
      {alwaysShowCardInput && (
        <>
          <s.SubTitle className="mt-4">Or add a new payment source</s.SubTitle>
          {CardInput(true)}
        </>
      )}
    </s.SelectPaymentWrapper>
  );

  return (
    <>
      {showEditPaymentMsg && (
        <Message
          success
          hasClose={false}
          title="Your payment source was successfully updated"
          description={`All future scheduled payments for this pay plan will be automatically charged to your card ending *${
            payout.paymentSource?.last4 ?? ''
          }`}
        />
      )}
      {isOverdue && (
        <OverdueBox toggleModalEditPayment={toggleModalEditPayment} attemptsCount={attemptsCount} payout={payout} />
      )}
      <PayoutInfo attemptsCount={attemptsCount} payout={payout} toggleModalMakePayment={toggleModalMakePayment} />
      <PayPlanDetails isOverdue={isOverdue} payout={payout} toggleModalEditPayment={toggleModalEditPayment} />
      <TransactionHistory payout={payout} />
      {showModalMakePayment && (
        <ModalV2
          cancelBtn={makePaymentCancelText}
          returnBackBtn={makePaymentReturnText}
          confirmBtn={makePaymentConfirmText}
          handleClose={handleCancelMakePayment}
          handleReturn={handleReturnMakePayment}
          handleConfirm={handleConfirmMakePayment}
          isLoading={isLoading}
          disableConfirmBtn={
            isLoading ||
            cardListLoading ||
            (!existCardSelected && (!!cardNumberError || !!expiryDateError || !!cardCvcError)) ||
            (!isCardFormReady && modalStep === ModalStepType.SelectPayment)
          }
          mobileInnerHeight={
            modalStep === ModalStepType.SelectInstalment
              ? '750px'
              : modalStep === ModalStepType.SelectPayment
              ? 'auto'
              : '660px'
          }
        >
          {modalStep === ModalStepType.SelectInstalment && (
            <SelectInstalment
              setPayAmount={setPayAmount}
              payout={payout}
              paymentOption={paymentOption}
              setPaymentOption={setPaymentOption}
            />
          )}
          {modalStep === ModalStepType.SelectPayment && SelectPaymentView('Make a payment', false)}
          {modalStep === ModalStepType.Success && <PaymentSuccess />}
        </ModalV2>
      )}
      {showModalEditPayment && (
        <ModalV2
          cancelBtn="Cancel"
          confirmBtn="Update payment source"
          handleClose={handleCancelEditPayment}
          handleConfirm={handleConfirmEditPayment}
          isLoading={isLoading}
          disableConfirmBtn={
            isLoading ||
            (!existCardSelected && (!!cardNumberError || !!expiryDateError || !!cardCvcError)) ||
            (existCardSelected && activePaymentSource === unconfirmedActivePaymentSource) ||
            !isCardFormReady
          }
          mobileInnerHeight="560px"
        >
          {SelectPaymentView('Update your payment source', true)}
        </ModalV2>
      )}
    </>
  );
};

export default OrderDetails;
