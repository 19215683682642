import getCurrentUserToken from './getCurrentUserToken';

type HeadersType = {
  Authorization: string;
  'Content-Type': string;
};

type ResultType = {
  method: string;
  headers: HeadersType;
  body?: string;
};

const getFetchOptions = async (method: string = 'GET', body?: string) => {
  const token = await getCurrentUserToken();
  const result: ResultType = {
    method,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  if (!!body) {
    result.body = body;
  }

  return result;
};

export default getFetchOptions;
