import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './index.css';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Store, applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';

import App from './App';
import { ActionType, DispatchType, ReduxStateType } from './redux/Constants/types';
import reducer from './redux/Reducers/';

const store: Store<ReduxStateType, ActionType> & {
  dispatch: DispatchType;
} = createStore(reducer, applyMiddleware(thunk));

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
