import styled from '@emotion/styled';

export const SectionHeader = styled.div`
  font-family: 'Geograph Bold';
  font-size: 20px;
  line-height: 24px;
  color: #191e33;
`;

export const Box = styled.div`
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  box-shadow: 0.5px 0.5px 3px rgba(220, 227, 235, 0.5);
  border-radius: 4px;
  padding: 36px 32px 40px 30px;
  @media (max-width: 768px) {
    padding: 20px 15px;
  }
`;

export const Text = styled.div`
  font-family: Geograph;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #555c7c;
`;

type FlexType = {
  sm?: string;
  color?: string;
};

export const Flex = styled.div<FlexType>`
  display: flex;
  align-items: center;
  position: relative;
  color: ${(props) => props.color ?? null};
  @media (max-width: 768px) {
    justify-content: ${(props) => props.sm ?? null};
  }
`;

export const UpdatePaymentBtn = styled.button`
  background-color: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
  margin-left: 20px;
  text-decoration: underline;
  font-size: 14px;
  line-height: 20px;
  color: var(--lp-colors-medium-blue-600);
  font-family: 'Geograph Bold';
  font-weight: 500;
  &:focus {
    outline: none;
  }
`;
