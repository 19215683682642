/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  customerDelegateOtpRequest,
  customerDelegateOtpRequestByOrgId,
  customerDelegateOtpSignIn,
  customerDelegateOtpSignInByOrgId,
  customerOTPRequest,
  customerOtpSignIn,
  signInWithCustomToken,
} from 'APIs/identity';
import { getErrorMessage } from 'APIs/utils';
import Checkbox from 'Components/Checkbox';
import Spinner from 'Components/Spinner/Spinner';
import { ReactComponent as ArrowRightIcon } from 'assets/svg/arrow-right.svg';
import { ReactComponent as EmailIcon } from 'assets/svg/email-new.svg';
import { ReactComponent as PhoneIcon } from 'assets/svg/phone.svg';
import { Location } from 'history';
import qs from 'query-string';
import React, { ChangeEvent, FormEvent, ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Dispatch } from 'redux';
import { setLoginPending } from 'redux/Actions/';
import { ReduxStateType } from 'redux/Constants/types';
import getMerchantName from 'utils/getMerchantName';
import validateEmail from 'utils/validateEmail';
import { clearZendeskWidget } from 'utils/zendesk';

import { LoginBtn, Wrapper } from './OTPLogin.style';

export type OTPLoginFormProps = {
  defaultValues?: Partial<{
    emailAddress: string;
    remember: boolean;
  }>;
  onSubmit?(emailAddress: string, remember: boolean): void;
};
export const OTPLoginForm = ({ defaultValues, onSubmit }: OTPLoginFormProps): ReactElement => {
  const [emailAddress, setEmailAddress] = useState<string>(defaultValues?.emailAddress || '');
  const [remember, setRemember] = useState(defaultValues?.remember || false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch: Dispatch<any> = useDispatch();
  const setLoginPendingCB = useCallback((pending: boolean) => dispatch(setLoginPending(pending)), [dispatch]);

  useEffect(() => {
    if (validateEmail(emailAddress)) setDisabled(false);
    else setDisabled(true);
  }, [emailAddress]);

  useEffect(() => {
    document.title = 'Sign in - April';
    setLoginPendingCB(true);
  }, [setLoginPendingCB]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmailAddress(event.target.value);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorMessage('');
    setLoading(true);
    try {
      await onSubmit?.(emailAddress, remember);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h5 className="box-title title-lg">Manage your Payment Plan</h5>
      <p>To log in, please enter the email address connected to your payment plan.</p>
      {!!errorMessage && <div className="alert">{errorMessage}</div>}
      <div className="inputs">
        <div className="input-group">
          <span className="input-group-addon">
            <EmailIcon width="24px" height="24px" />
          </span>
          <input
            className="form-control"
            type="email"
            autoFocus
            data-testid="customerEmail"
            name="email"
            placeholder="Email"
            value={emailAddress}
            onChange={handleChange}
          />
        </div>
        <Checkbox checked={remember} handleChange={() => setRemember(!remember)} text="Remember me on this device" />
      </div>

      <div className="box-footer">
        <LoginBtn type="submit" isLoading={loading} disabled={disabled || loading} data-testid="submitLoginForm">
          {!loading && (
            <>
              Log in
              <ArrowRightIcon className="arrow-right" />
            </>
          )}
          {loading && <Spinner width="20px" height="20px" borderWidth="2px" />}
        </LoginBtn>
      </div>
    </form>
  );
};

export type OTPVerifyFormProps = {
  emailAddress: string;
  phoneNumberlast4?: string;
  onSubmit?(emailVerificationCode: string, phoneVerificationCode: string): void;
  onBack?(): void;
  sendOtpRequest?(emailAddress: string): void;
};

export const OTPVerifyForm = ({
  emailAddress,
  phoneNumberlast4,
  onSubmit,
  onBack,
  sendOtpRequest,
}: OTPVerifyFormProps): ReactElement => {
  const [emailVerificationCode, setEmailVerificationCode] = useState('');
  const [phoneVerificationCode, setPhoneVerificationCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (emailVerificationCode) setDisabled(false);
    else setDisabled(true);
  }, [emailVerificationCode]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSuccessMessage('');
    setErrorMessage('');
    setLoading(true);
    try {
      await onSubmit?.(emailVerificationCode, phoneVerificationCode);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
      setLoading(false);
    }
  };

  // Resends verification codes via both phone and email, different than lime-web
  const handleResendCodes = async (event: React.MouseEvent<HTMLElement>) => {
    setPhoneVerificationCode('');
    setEmailVerificationCode('');
    setErrorMessage('');
    setSuccessMessage('');
    try {
      await sendOtpRequest?.(emailAddress);
      setSuccessMessage('Codes resent successfully');
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h5 className="box-title title-lg">Verify your contact details</h5>
      <p>
        {phoneNumberlast4 ? (
          <span>
            We’ve sent codes to <b>***{phoneNumberlast4}</b> and <b>{emailAddress}</b>. Please enter them below.
          </span>
        ) : (
          <span>
            We’ve sent a code to <b>{emailAddress}</b>. Please enter it below.
          </span>
        )}
      </p>
      {!!errorMessage && <div className="alert">{errorMessage}</div>}
      {!!successMessage && <div className="alert alert-success">{successMessage}</div>}
      <div className="inputs">
        {!!phoneNumberlast4 && (
          <div className="input-group">
            <span className="input-group-addon">
              <PhoneIcon width="24px" height="24px" />
            </span>
            <input
              className="form-control"
              type="text"
              pattern="\d*"
              inputMode="numeric"
              autoComplete="off"
              autoFocus
              data-testid="customerPhoneCode"
              name="mobile code"
              placeholder="Mobile code"
              value={phoneVerificationCode}
              onChange={(event) => setPhoneVerificationCode(event.target.value)}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              minLength={6}
              maxLength={6}
            />
          </div>
        )}
        <div className="input-group">
          <span className="input-group-addon">
            <EmailIcon width="24px" height="24px" />
          </span>
          <input
            className="form-control"
            type="text"
            pattern="\d*"
            inputMode="numeric"
            autoComplete="off"
            data-testid="customerEmailCode"
            name="email code"
            placeholder="Email code"
            value={emailVerificationCode}
            onChange={(event) => setEmailVerificationCode(event.target.value)}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            minLength={6}
            maxLength={6}
          />
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-sm-6">
          <a className="lpResendCodes" href="#" onClick={handleResendCodes}>
            Resend Codes
          </a>
        </div>
      </div>

      <div className="box-footer">
        <LoginBtn isLoading={loading} type="submit" disabled={disabled || loading} data-testid="submitCodeForm">
          {!loading && (
            <>
              Verify
              <ArrowRightIcon className="arrow-right" />
            </>
          )}
          {loading && <Spinner width="20px" height="20px" borderWidth="2px" />}
        </LoginBtn>
        <p className="go-back" onClick={() => onBack?.()}>
          Go back
        </p>
      </div>
    </form>
  );
};

export enum OTPLoginStep {
  Login,
  Verify,
}
const OTPLogin = (): ReactElement => {
  const [emailAddress, setEmailAddress] = useState('');
  const [remember, setRemember] = useState(false);
  const [phoneNumberlast4, setPhoneNumberlast4] = useState<string>();
  const [currentStep, setCurrentStep] = useState<OTPLoginStep>(OTPLoginStep.Login);
  const [merchantName] = useState(() => getMerchantName());

  const { apiBaseUri, tenantId, merchantId } = useSelector((state: ReduxStateType) => ({
    apiBaseUri: state.apiBaseUri,
    tenantId: state.tenantId,
    merchantId: state.merchantId,
  }));

  useEffect(() => {
    clearZendeskWidget();
  }, []);

  const location: Location<{ from?: Location } | null | undefined> = useLocation();
  const { orgCustomerId, limepayCustomerId, email = '', path = '' } = qs.parse(window.location.search);

  const sendOtpRequest = async (emailAddress: string) => {
    if (orgCustomerId) {
      const { phoneNumberlast4 } = await customerDelegateOtpRequestByOrgId(apiBaseUri, {
        orgCustomerId: orgCustomerId as string,
        delegateEmail: emailAddress,
        merchantId,
      });
      return phoneNumberlast4;
    }
    if (limepayCustomerId) {
      const { phoneNumberlast4 } = await customerDelegateOtpRequest(apiBaseUri, {
        limepayCustomerId: limepayCustomerId as string,
        delegateEmail: emailAddress,
        merchantId,
      });
      return phoneNumberlast4;
    }
    const { phoneNumberlast4 } = await customerOTPRequest(apiBaseUri, {
      emailAddress,
      merchantId,
    });
    return phoneNumberlast4;
  };

  const sendOtpSignIn = async (emailAddress: string, emailVerificationCode: string, phoneVerificationCode: string) => {
    if (orgCustomerId) {
      const { customToken } = await customerDelegateOtpSignInByOrgId(apiBaseUri, {
        orgCustomerId: orgCustomerId as string,
        merchantId,
        delegateEmail: emailAddress,
        emailVerificationCode,
        phoneVerificationCode: phoneVerificationCode || undefined,
      });
      return customToken;
    }
    if (limepayCustomerId) {
      const { customToken } = await customerDelegateOtpSignIn(apiBaseUri, {
        limepayCustomerId: limepayCustomerId as string,
        merchantId,
        delegateEmail: emailAddress,
        emailVerificationCode,
        phoneVerificationCode: phoneVerificationCode || undefined,
      });
      return customToken;
    }
    const { customToken } = await customerOtpSignIn(apiBaseUri, {
      merchantId,
      emailAddress,
      emailVerificationCode,
      phoneVerificationCode,
    });
    return customToken;
  };

  const handleLoginSubmit: NonNullable<OTPLoginFormProps['onSubmit']> = async (emailAddress, remember) => {
    setEmailAddress(emailAddress);
    setRemember(remember);
    const phoneNumberlast4 = await sendOtpRequest(emailAddress);
    setPhoneNumberlast4(phoneNumberlast4);
    setCurrentStep(OTPLoginStep.Verify);
  };

  const handleVerifySubmit: NonNullable<OTPVerifyFormProps['onSubmit']> = async (
    emailVerificationCode,
    phoneVerificationCode,
  ) => {
    const customToken = await sendOtpSignIn(emailAddress, emailVerificationCode, phoneVerificationCode);
    await signInWithCustomToken(tenantId, customToken, remember);
    window.location.href =
      decodeURIComponent(path as string) || location.state?.from?.pathname || `/${merchantName}/purchases`;
  };

  return (
    <Wrapper>
      <div className="otp-auth-box">
        {currentStep === OTPLoginStep.Login && (
          <OTPLoginForm
            defaultValues={{ emailAddress: emailAddress || decodeURIComponent(email as string), remember }}
            onSubmit={handleLoginSubmit}
          />
        )}
        {currentStep === OTPLoginStep.Verify && (
          <OTPVerifyForm
            emailAddress={emailAddress}
            phoneNumberlast4={phoneNumberlast4}
            onSubmit={handleVerifySubmit}
            sendOtpRequest={sendOtpRequest}
            onBack={() => setCurrentStep(OTPLoginStep.Login)}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default OTPLogin;
