export const TabOptions = [
  {
    text: 'General',
    value: 'general',
  },
  {
    text: 'Cards',
    value: 'cards',
  },
];
