import { ConsumerCustomerCardSource, VAULT_CARD, vaultCreatePaymentSource } from 'APIs/vault';
import Card from 'Components/Card';
import Message from 'Components/Message/';
import ModalV2 from 'Components/ModalV2/';
import Spinner from 'Components/Spinner/Spinner';
import { ReactComponent as CardNumberIcon } from 'assets/svg/card-number.svg';
import { ReactComponent as CVCIcon } from 'assets/svg/cvc.svg';
import { ReactComponent as ExpiryIcon } from 'assets/svg/expiry.svg';
import React, { MouseEvent, ReactElement, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ReduxStateType } from 'redux/Constants/types';
import formatVgsError from 'utils/formatVgsError';
import getCurrentUserToken from 'utils/getCurrentUserToken';
import getFetchOptions from 'utils/getFetchOptions';
import getFormattedCardBrand from 'utils/getFormattedCardBrand';
import getMerchantName from 'utils/getMerchantName';
import handleApiError from 'utils/handleApiError';
import setBodyOverflow from 'utils/setBodyOverflow';
import { fieldStyles, getVaultHost } from 'utils/vault';
import { hideZendeskWidget, showZendeskWidget } from 'utils/zendesk';

import { Vault, createVault } from '@april/lib-ui';

import { cardCvcId, cardExpiryId, cardNumberId } from './Constants';
import * as s from './styles';
import { CardType } from './types';

export type CardsProps = {
  cards: CardType[];
};

const Cards = (): ReactElement => {
  const [paymentCards, setPaymentCards] = useState<CardType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsgTitle, setErrorMsgTitle] = useState<string>('');
  const [errorMsgDesc, setErrorMsgDesc] = useState<string>('');
  const [showAddCardMsg, setShowAddCardMsg] = useState<boolean>(false);
  const [showDeleteCardMsg, setShowDeleteCardMsg] = useState<boolean>(false);
  const [timestamp, setTimestamp] = useState<number>(0);
  const [cardListLoading, setCardListLoading] = useState<boolean>(true);
  const [cardInUse, setCardInUse] = useState<boolean>(false);
  const [disableAddCard, setDisableAddCard] = useState<boolean>(true);

  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [showModalAdd, setShowModalAdd] = useState<boolean>(false);

  const [selectedCardId, setSelectedCardId] = useState<string>('');
  const [selectedCardBrand, setSelectedCardBrand] = useState<string>('');
  const [selectedCardLast4, setSelectedCardLast4] = useState<string>('');
  const [hoverCardId, setHoverCardId] = useState<string>('');

  const [vaultCard, setVaultCard] = useState<Vault>();
  const [cardNumberError, setCardNumberError] = useState<string>('');
  const [expiryDateError, setExpiryDateError] = useState<string>('');
  const [cardCvcError, setCardCvcError] = useState<string>('');

  const [merchantName] = useState(() => getMerchantName());

  const { apiBaseUri, customerId, merchantTags } = useSelector((state: ReduxStateType) => ({
    apiBaseUri: state.apiBaseUri,
    customerId: state.customerId,
    merchantTags: state.merchantTags,
  }));

  useEffect(() => {
    if (!showAddCardMsg) {
      return;
    }
    const timer = setTimeout(() => {
      setShowAddCardMsg(false);
    }, 4e3);
    return () => clearTimeout(timer);
  }, [showAddCardMsg]);

  useEffect(() => {
    if (!showDeleteCardMsg) {
      return;
    }
    const timer = setTimeout(() => {
      setShowDeleteCardMsg(false);
    }, 4e3);
    return () => clearTimeout(timer);
  }, [showDeleteCardMsg]);

  useEffect(() => {
    if (!apiBaseUri || !customerId) {
      return;
    }

    const fetchListOfCards = async () => {
      const url = `${apiBaseUri}/customers/${customerId}/payments/sources/card`;
      const options = await getFetchOptions();
      setCardListLoading(true);
      fetch(url, options)
        .then(async (res) => {
          if (!res.ok) {
            await handleApiError(res);
          }
          return res.json();
        })
        .then((response) => {
          setErrorMsgTitle('');
          setPaymentCards(response);
        })
        .catch((e) => {
          setErrorMsgTitle(e?.message || 'Failed to fetch cards');
        })
        .finally(() => {
          setCardListLoading(false);
        });
    };
    fetchListOfCards();
  }, [apiBaseUri, customerId, timestamp]);

  const toggleModalAdd = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowModalAdd(true);
    setCardNumberError('');
    setExpiryDateError('');
    setCardCvcError('');
    setErrorMsgTitle('');
    setErrorMsgDesc('');
    setBodyOverflow('hidden');
    hideZendeskWidget();
  };

  const toggleModalDelete = (e: MouseEvent<HTMLButtonElement | HTMLDivElement>, cardPaymentSourceId: string): void => {
    e.preventDefault();
    const card = paymentCards.find((c) => c.cardPaymentSourceId === cardPaymentSourceId);
    setSelectedCardBrand(getFormattedCardBrand(card?.brand || ''));
    setSelectedCardLast4(card?.last4 || '');
    setShowModalDelete(true);
    setSelectedCardId(cardPaymentSourceId);
    setBodyOverflow('hidden');
    setHoverCardId('');
    setCardInUse(false);
    setErrorMsgTitle('');
    setErrorMsgDesc('');
    hideZendeskWidget();
  };

  const handleCancel = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowModalDelete(false);
    setShowModalAdd(false);
    setSelectedCardId('');
    setErrorMsgTitle('');
    setErrorMsgDesc('');
    setIsLoading(false);
    setBodyOverflow('auto');
    setCardInUse(false);
    setSelectedCardBrand('');
    setSelectedCardLast4('');
    showZendeskWidget();
  };

  const handleConfirmDelete = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (cardInUse) {
      setSelectedCardId('');
      setBodyOverflow('auto');
      setShowModalDelete(false);
      showZendeskWidget();
    } else {
      handleSubmitDelete();
    }
  };

  const handleCloseMessage = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setErrorMsgTitle('');
    setErrorMsgDesc('');
  };

  const handleSubmitDelete = async (): Promise<void> => {
    setIsLoading(true);
    const url = `${apiBaseUri}/customers/${customerId}/payments/sources/card/${selectedCardId}`;
    const options = await getFetchOptions('DELETE');

    fetch(url, options)
      .then(async (res) => {
        if (res.status === 400) {
          setCardInUse(true);
          throw Error();
        }
        if (!res.ok) {
          await handleApiError(res);
        }
        return res.json();
      })
      .then(() => {
        setErrorMsgTitle('');
        window.scrollTo({ top: 0 });
        setBodyOverflow('auto');
        setShowModalDelete(false);
        setShowDeleteCardMsg(true);
        setTimestamp(Date.now());
        showZendeskWidget();
      })
      .catch((e) => {
        setErrorMsgTitle(e.message || 'Failed to delete this card');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadVaultCard = useCallback(async () => {
    setDisableAddCard(true);

    const vault = await createVault({
      vaultHost: await getVaultHost(),
      fields: {
        cardNumber: { elementId: cardNumberId, fieldStyles },
        expiryDate: { elementId: cardExpiryId, fieldStyles },
        cardCvc: { elementId: cardCvcId, fieldStyles },
      },
      onEvent: ({ eventName, field, vault }) => {
        if (eventName === 'Change') {
          const { isValid } = vault.validate();

          if (field.type === 'cardNumber') {
            setCardNumberError('');
          }
          if (field.type === 'expiryDate') {
            setExpiryDateError('');
          }
          if (field.type === 'cardCvc') {
            setCardCvcError('');
          }

          setDisableAddCard(!isValid);
        }
      },
    });
    setVaultCard(vault);
  }, []);

  useEffect(() => {
    (async () => {
      if (!showModalAdd) return;

      await loadVaultCard();
    })();
  }, [loadVaultCard, merchantTags, showModalAdd]);

  const handleSubmitAdd = useCallback(
    async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
      e.preventDefault();

      try {
        setIsLoading(true);

        if (!vaultCard) throw Error();

        const token = await getCurrentUserToken();
        const { cardError, response } = await vaultCreatePaymentSource<ConsumerCustomerCardSource>(vaultCard, token, {
          CreateConsumerCardSource: {
            ...VAULT_CARD,
          },
        });

        if (cardError || !response) {
          setErrorMsgDesc(cardError?.formError || 'Failed to add a new card');
          throw Error();
        }

        setShowAddCardMsg(true);
        setBodyOverflow('auto');
        window.scrollTo({ top: 0 });
        setShowModalAdd(false);
        setTimestamp(Date.now());
        showZendeskWidget();
      } catch (e) {
        setErrorMsgTitle('Failed to save the card');
      } finally {
        setIsLoading(false);
      }
    },
    [vaultCard],
  );

  return (
    <>
      {showAddCardMsg && (
        <s.SuccessMsg>
          <Message hasClose={false} title="Your card was saved successfully" />
        </s.SuccessMsg>
      )}
      {showDeleteCardMsg && (
        <s.SuccessMsg>
          <Message hasClose={false} title="Your card was successfully deleted" />
        </s.SuccessMsg>
      )}
      <s.Box>
        <s.SectionTitle>Your cards</s.SectionTitle>
        <s.Group>
          <s.Description className="mt-4">
            Manage your saved cards below. You can use these to check out faster in the future.
          </s.Description>
          <s.Description className="mt-2">
            To update the card used for upcoming scheduled payments, please select your Order ID in
            <NavLink className="link" to={`/${merchantName}/purchases`}>
              Purchases
            </NavLink>
            and update the payment source of each active pay plan.
          </s.Description>
          <s.AddCardBtn onClick={toggleModalAdd}>Add a new card</s.AddCardBtn>
        </s.Group>
        <s.Split />
        {cardListLoading && (
          <s.CardListSpinner>
            <Spinner color="#0016D1" />
          </s.CardListSpinner>
        )}
        {!cardListLoading && paymentCards.length > 0 && (
          <s.CardsGroup>
            {paymentCards.map((card) => (
              <s.CardWrapper
                key={card.cardPaymentSourceId}
                onClick={(e) => toggleModalDelete(e, card.cardPaymentSourceId)}
                onMouseLeave={() => setHoverCardId('')}
                onMouseEnter={() => setHoverCardId(card.cardPaymentSourceId)}
              >
                <Card className="card" {...card} />
                {hoverCardId === card.cardPaymentSourceId && (
                  <s.CardCover>
                    <s.DeleteBtn onClick={(e) => toggleModalDelete(e, card.cardPaymentSourceId)}>Delete</s.DeleteBtn>
                  </s.CardCover>
                )}
                <s.MobileDeleteLink>Delete</s.MobileDeleteLink>
              </s.CardWrapper>
            ))}
          </s.CardsGroup>
        )}
        {!cardListLoading && paymentCards.length === 0 && <s.Empty>No results found</s.Empty>}
        {showModalDelete && (
          <ModalV2
            isDanger={!cardInUse}
            cancelBtn={cardInUse ? '' : 'Cancel'}
            confirmBtn={cardInUse ? 'Close' : 'Delete'}
            handleClose={handleCancel}
            handleConfirm={handleConfirmDelete}
            isLoading={isLoading}
            disableConfirmBtn={isLoading}
            btnPosition="center"
            minWidth="360px"
            minHeight={cardInUse ? '270px' : '240px'}
            mobileInnerHeight="250px"
          >
            {!cardInUse && (
              <s.ModalWrapper>
                <s.ModalTitle>Confirm card deletion</s.ModalTitle>
                <s.ModalSplit />
                {errorMsgTitle.length > 0 && (
                  <Message success={false} title={errorMsgTitle} handleClose={handleCloseMessage} />
                )}
                {errorMsgTitle.length === 0 && (
                  <s.ModalContent>
                    Are you sure you want to delete the {selectedCardBrand} card ending in *{selectedCardLast4} from
                    your account?
                  </s.ModalContent>
                )}
              </s.ModalWrapper>
            )}
            {cardInUse && (
              <s.ModalWrapper>
                <s.ModalTitle>Card in use</s.ModalTitle>
                <s.ModalSplit />
                <s.ModalContent>
                  This card can not be deleted at this time as it is linked to an active payment plan. Please update the
                  payment source on your active plan/s to delete this card.
                </s.ModalContent>
              </s.ModalWrapper>
            )}
          </ModalV2>
        )}
        {showModalAdd && (
          <ModalV2
            cancelBtn="Cancel"
            confirmBtn="Save card"
            isLoading={isLoading}
            disableConfirmBtn={isLoading || disableAddCard || !!cardNumberError || !!expiryDateError || !!cardCvcError}
            handleClose={handleCancel}
            handleConfirm={handleSubmitAdd}
            minHeight="336px"
            mobileInnerHeight="340px"
          >
            <s.AddCardWrapper>
              <s.ModalTitle>Add a new card</s.ModalTitle>
              <s.ModalSplit />
              {errorMsgTitle.length > 0 && (
                <Message
                  success={false}
                  title={errorMsgTitle}
                  description={errorMsgDesc}
                  handleClose={handleCloseMessage}
                />
              )}
              <s.StripeWrapper>
                <s.CardNumber>
                  <CardNumberIcon className="card-number-icon" />
                  <s.StripeInput id={cardNumberId} />
                  <s.StripeErrorMsg hasError={!!cardNumberError}>{formatVgsError(cardNumberError)}</s.StripeErrorMsg>
                </s.CardNumber>

                <s.StripeRow className="mt-3">
                  <s.Expiry>
                    <ExpiryIcon className="expiry-icon" />
                    <s.StripeInput id={cardExpiryId} />
                    <s.StripeErrorMsg hasError={!!expiryDateError}>{formatVgsError(expiryDateError)}</s.StripeErrorMsg>
                  </s.Expiry>
                  <s.Cvc>
                    <CVCIcon className="cvc-icon" />
                    <s.StripeInput id={cardCvcId} />
                    <s.StripeErrorMsg hasError={!!cardCvcError}>{formatVgsError(cardCvcError)}</s.StripeErrorMsg>
                  </s.Cvc>
                </s.StripeRow>
              </s.StripeWrapper>
            </s.AddCardWrapper>
          </ModalV2>
        )}
      </s.Box>
    </>
  );
};

export default Cards;
