import styled from '@emotion/styled';

type WrapperType = {
  isSelected: boolean;
};
export const Wrapper = styled.div<WrapperType>`
  width: 163px;
  height: 104px;
  border: ${(props) => (props.isSelected ? '2px solid #80c1a5' : 'none')};
  box-shadow: 0px 4px 24px -4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  background: ${(props) => (props.isSelected ? '#323750' : '#191E33')};
  position: relative;
  cursor: pointer;
  @media (max-width: 492px) {
    width: 158px;
  }
`;

export const Visa = styled.div`
  position: absolute;
  top: 20px;
  left: 17px;
  width: 47px;
  height: 16px;
  svg {
    width: 100%;
    height: 100%;
  }
  g {
    fill: white;
  }
`;

export const MasterCard = styled.div`
  width: 39px;
  height: 24px;
  position: absolute;
  top: 20px;
  left: 17px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Amex = styled.div`
  width: 39px;
  height: 24px;
  position: absolute;
  top: 13px;
  left: 8px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Diners = styled.div`
  width: 39px;
  height: 24px;
  position: absolute;
  top: 13px;
  left: 10px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const UnionPay = styled.div`
  width: 39px;
  height: 24px;
  position: absolute;
  top: 13px;
  left: 15px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const JCB = styled.div`
  width: 39px;
  height: 24px;
  position: absolute;
  top: 13px;
  left: 12px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Discover = styled.div`
  width: 40px;
  height: 15px;
  position: absolute;
  top: 13px;
  left: 10px;
  background-color: white;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Expiry = styled.div`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  position: absolute;
  color: #fff;
  top: 16px;
  right: 16px;
`;

type DotsType = {
  index: number;
};

export const Dots = styled.div<DotsType>`
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 2px;
  opacity: 0.5;
  position: absolute;
  bottom: 27px;
  left: calc(${({ index }) => index} * 33px + 17px);
  height: 28px;
  width: 21px;
  color: #ffffff;
`;

export const Last4 = styled.div`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #ffffff;
  position: absolute;
  bottom: 30px;
  left: 116px;
`;
