import Spinner from 'Components/Spinner/Spinner';
import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left-new.svg';
import { ReactComponent as Close } from 'assets/svg/close-new.svg';
import { MouseEvent, ReactElement, ReactNode } from 'react';
import { createPortal } from 'react-dom';

import * as s from './styles';

type ModalType = {
  cancelBtn: string;
  confirmBtn: string;
  returnBackBtn?: string;
  handleClose(e: MouseEvent<HTMLButtonElement>): void;
  handleReturn?(e: MouseEvent<HTMLButtonElement>): void;
  handleConfirm?(e: MouseEvent<HTMLButtonElement>): void;
  disableConfirmBtn?: boolean;
  isLoading?: boolean;
  isDanger?: boolean;
  btnPosition?: string;
  minWidth?: string;
  minHeight?: string;
  mobileInnerHeight?: string;
  children: ReactNode;
};

const modalRoot = document.getElementById('modal-root');

const Modal = ({
  cancelBtn,
  returnBackBtn,
  confirmBtn,
  handleClose,
  handleReturn,
  handleConfirm,
  isLoading = false,
  disableConfirmBtn = false,
  isDanger = false,
  btnPosition = 'flex-end',
  minWidth = '450px',
  minHeight = '300px',
  mobileInnerHeight = '100%',
  children,
}: ModalType): ReactElement => {
  if (!modalRoot) {
    return <></>;
  }

  const element = (
    <s.Outer>
      <s.Fade />
      <s.Inner mobileInnerHeight={mobileInnerHeight} minWidth={minWidth} minHeight={minHeight}>
        <s.CloseBtn onClick={handleClose} data-testid="modal-close-btn">
          <Close />
        </s.CloseBtn>
        {children}
        <s.BtnGroup btnPosition={btnPosition} className="ModalBtnGroup">
          {!!returnBackBtn && (
            <s.ReturnBackBtn onClick={handleReturn}>
              <ArrowLeft className="arrow-left" />
              <s.ReturnBackText>{returnBackBtn}</s.ReturnBackText>
            </s.ReturnBackBtn>
          )}
          {!!cancelBtn && <s.CancelBtn onClick={handleClose}>{cancelBtn}</s.CancelBtn>}
          {!!confirmBtn && (
            <s.ConfirmBtn
              isDanger={isDanger}
              onClick={handleConfirm}
              disabled={disableConfirmBtn}
              data-testid="modal-button-confirm"
            >
              {confirmBtn}
              {isLoading && (
                <s.SpinnerWrapper>
                  <Spinner width="20px" height="20px" borderWidth="2px" />
                </s.SpinnerWrapper>
              )}
            </s.ConfirmBtn>
          )}
        </s.BtnGroup>
      </s.Inner>
    </s.Outer>
  );

  return createPortal(element, modalRoot);
};

export default Modal;
