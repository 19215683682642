import { EntryType } from 'Components/Payments/types';
import { LpDetails } from 'Constants/styles';
import { Fragment, ReactElement, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { toCurrency } from 'utils/currency';

import { TableHr, TableRow, TableText } from './TransactionHistory.styles';
import { getObjFromCharges, getObjFromLedger } from './getTransactionObj';
import { Box, SectionHeader } from './styles';
import { TransactionHistoryType, TransactionType } from './types';

const TransactionHistory = ({ payout }: TransactionHistoryType): ReactElement => {
  const [transactions, setTransactions] = useState<TransactionType[]>([]);

  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });

  useEffect(() => {
    const array: TransactionType[] = [];
    const ledgerEntries = payout.ledger.entries;
    const chargerEntries = payout.charges.entries;
    const { chargesPaymentSources } = payout;
    let i = 0;
    let j = 0;
    let totalBalance = 0;

    while (i < ledgerEntries.length && j < chargerEntries.length) {
      const ith = ledgerEntries[i];
      const jth = chargerEntries[j];

      if (ith.entryType === EntryType.Charge || ith.amount === 0) {
        ++i;
        continue;
      }

      if (jth.chargedAmount === 0) {
        ++j;
        continue;
      }

      const time1 = new Date(ith.entryTime).getTime();
      const time2 = new Date(jth.createdAt).getTime();
      if (time1 < time2) {
        const obj = getObjFromLedger(ith, totalBalance);
        totalBalance = obj.balance;
        array.unshift({ ...obj });
        ++i;
      } else {
        const obj = getObjFromCharges(jth, totalBalance, chargesPaymentSources);
        totalBalance = obj.balance;
        array.unshift({ ...obj });
        ++j;
      }
    }

    while (i < ledgerEntries.length) {
      const ith = ledgerEntries[i];
      if (ith.entryType === EntryType.Charge || ith.amount === 0) {
        ++i;
        continue;
      }
      const obj = getObjFromLedger(ith, totalBalance);
      totalBalance = obj.balance;
      array.unshift({ ...obj });
      ++i;
    }

    while (j < chargerEntries.length) {
      const jth = chargerEntries[j];
      if (jth.chargedAmount === 0) {
        ++j;
        continue;
      }

      const obj = getObjFromCharges(jth, totalBalance, chargesPaymentSources);
      totalBalance = obj.balance;
      array.unshift({ ...obj });
      ++j;
    }

    setTransactions(array);
  }, [payout]);

  if (payout.ledger.entries.length === 0 || transactions.length === 0) {
    return <></>;
  }

  return (
    <Box className="mt-4">
      <LpDetails>
        <SectionHeader>Transaction history</SectionHeader>
        <TableRow className="mt-4">
          {!isMobile && <TableText width="80px">Date</TableText>}
          <TableText left="25px" className="header-description">
            Description
          </TableText>
          {!isMobile && (
            <TableText width="80px" left="auto" align="right">
              Money out
            </TableText>
          )}
          {!isMobile && (
            <TableText width="80px" left="16px" align="right">
              Money in
            </TableText>
          )}
          <TableText width="80px" left="16px" align="right">
            Balance
          </TableText>
        </TableRow>

        {transactions.map((t, index) => (
          <Fragment key={index}>
            <TableHr />
            {isMobile && (
              <TableRow>
                <TableText left="0" color="#191E33" weight={700} dangerouslySetInnerHTML={{ __html: t.description }} />
              </TableRow>
            )}
            <TableRow className="mt-2 mt-md-0">
              <TableText width="80px" color="#191E33">
                {t.time}
              </TableText>
              {!isMobile && (
                <TableText
                  left="25px"
                  color="#191E33"
                  data-testid="order-detail-transaction-description"
                  dangerouslySetInnerHTML={{ __html: t.description }}
                />
              )}
              {isMobile && (
                <TableText width="80px" left="24px" color={t.debitAmount !== null ? '#FF6E44' : '#749FAA'}>
                  {t.debitAmount !== null || t.creditAmount !== null
                    ? toCurrency(t.debitAmount || t.creditAmount || 0, payout.currency)
                    : ''}
                </TableText>
              )}
              {!isMobile && (
                <TableText
                  width="80px"
                  left="auto"
                  align="right"
                  color="#FF6E44"
                  data-testid="order-detail-transaction-moneyout"
                >
                  {t.debitAmount !== null ? toCurrency(t.debitAmount, payout.currency) : null}
                </TableText>
              )}
              {!isMobile && (
                <TableText
                  width="80px"
                  left="16px"
                  align="right"
                  color="#749FAA"
                  data-testid="order-detail-transaction-moneyin"
                >
                  {t.creditAmount !== null ? toCurrency(t.creditAmount, payout.currency) : null}
                </TableText>
              )}
              <TableText width="80px" left="16px" align="right" color="#191E33" className="balance">
                {toCurrency(t.balance, payout.currency)}
              </TableText>
            </TableRow>
          </Fragment>
        ))}
      </LpDetails>
    </Box>
  );
};

export default TransactionHistory;
