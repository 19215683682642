import { APIResponseType } from 'Components/Payments/types';

import {
  SET_ACTIVE_PAYMENT,
  SET_CONFIG,
  SET_CUSTOMER_ID,
  SET_CUSTOMER_INFO,
  SET_FETCH_TIMESTAMP,
  SET_LOGIN_PENDING,
  SET_PAYMENTS_TAB_ID,
  UPDATE_LOGGED_IN,
} from '../Constants/actionTypes';
import { ActionType, BrandingType, ReduxStateType } from '../Constants/types';

export const setConfig = (
  apiBaseUri: string,
  tenantId: string,
  merchantId: string,
  branding: BrandingType,
  tcUrl: string,
  merchantTags: ReduxStateType['merchantTags'],
): ActionType => ({
  type: SET_CONFIG,
  payload: {
    apiBaseUri,
    tenantId,
    merchantId,
    branding,
    tcUrl,
    merchantTags,
  },
});

export const setLoginPending = (isLoginPending: boolean): ActionType => ({
  type: SET_LOGIN_PENDING,
  payload: {
    isLoginPending,
  },
});

export const updateLoggedIn = (isLoggedIn: boolean): ActionType => ({
  type: UPDATE_LOGGED_IN,
  payload: {
    isLoggedIn,
  },
});

export const setCustomerId = (customerId: string): ActionType => ({
  type: SET_CUSTOMER_ID,
  payload: {
    customerId,
  },
});

export const setFetchTimestamp = (timestamp: number): ActionType => ({
  type: SET_FETCH_TIMESTAMP,
  payload: {
    timestamp,
  },
});

export const setCustomerInfo = (
  email: string,
  phoneNumber: string | null,
  firstName: string | null,
  lastName: string | null,
): ActionType => ({
  type: SET_CUSTOMER_INFO,
  payload: {
    email,
    phoneNumber,
    firstName,
    lastName,
  },
});

export const setActivePayment = (
  showPaymentDetailsPage: boolean,
  activePayment: APIResponseType | null,
): ActionType => ({
  type: SET_ACTIVE_PAYMENT,
  payload: {
    showPaymentDetailsPage,
    activePayment,
  },
});

export const setPaymentsTabId = (paymentsTabId: number): ActionType => ({
  type: SET_PAYMENTS_TAB_ID,
  payload: {
    paymentsTabId,
  },
});
