import { APIResponseType } from 'Components/Payments/types';
import { toCurrency } from 'utils/currency';
import getDateFormat from 'utils/getDateFormat';

import { PaymentOptionType, PaymentScheduleType } from './types';

export const getDescription = (payout: APIResponseType, option: PaymentOptionType): string => {
  if (option === PaymentOptionType.NextPayment) {
    const remains = (payout.planAmountOutstanding ?? 0) - payout.schedule[0].amount;
    const amount = toCurrency(remains, payout.currency);
    return `Your outstanding balance will be <strong data-testid="payment-description-amount">${amount}</strong> after this payment`;
  }
  return 'You will complete your pay plan after this payment  👏';
};

export const calculatePaymentsSchedule = (
  { charges, schedule, planAmountOutstanding, chargesPaymentSources, currency }: APIResponseType,
  paymentOption: PaymentOptionType,
): PaymentScheduleType[] => {
  const array: PaymentScheduleType[] = [];
  charges.entries.forEach((entry) => {
    if (entry.isSuccess === true) {
      const { date } = getDateFormat({
        time: entry.createdAt,
        showShortMonth: true,
        showDaySuffix: false,
        showComma: false,
        showYear: false,
      });
      const amount = toCurrency(entry.chargedAmount, currency);
      const { brand, last4 } = chargesPaymentSources[entry.cardPaymentSourceId];
      const obj = {
        title: `Payment ${array.length + 1}`,
        status: 'Paid',
        amount,
        paidDate: `Paid ${date}`,
        hasDescription: false,
        isActive: false,
        cardInfo: `${brand?.toUpperCase()} x-${last4}`,
      };
      array.push({ ...obj });
    }
  });
  if (schedule.length > 0) {
    const amount = paymentOption === PaymentOptionType.PayAll ? planAmountOutstanding : schedule[0].amount;

    const formattedAmount = toCurrency(amount ?? 0, currency);
    const obj = {
      title: `Payment ${array.length + 1}`,
      status: 'Paying',
      amount: formattedAmount,
      paidDate: '',
      hasDescription: true,
      isActive: true,
      cardInfo: '',
    };
    array.push({ ...obj });
  }

  if (schedule.length > 1 && paymentOption !== PaymentOptionType.PayAll) {
    for (let i = 1; i < schedule.length; i++) {
      const amount = toCurrency(schedule[i].amount, currency);
      const { date } = getDateFormat({
        time: schedule[i].at,
        showShortMonth: true,
        showDaySuffix: false,
        showComma: false,
        showYear: false,
      });
      const obj = {
        title: `Due ${date}`,
        status: '',
        amount,
        paidDate: '',
        hasDescription: false,
        isActive: false,
        cardInfo: '',
      };
      array.push({ ...obj });
    }
  }
  return array;
};
