import React, { useState } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import getMerchantName from './utils/getMerchantName';

interface PrivateRouteProps extends RouteProps {
  isLoggedIn: boolean;
  component: any;
  token?: string;
}

const PrivateRoute = ({ isLoggedIn, token, component: Component, ...rest }: PrivateRouteProps) => {
  const [merchantName] = useState<string>(() => getMerchantName());
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn || !!token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: `/${merchantName}/login/${props.location.search}`, state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
