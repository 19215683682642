import './Tabs.scss';

import React, { ReactElement, useState } from 'react';

type OptionProps = {
  text: string;
  value: string;
};

export type TabsProps = {
  children: ReactElement[];
  tabOptions: OptionProps[];
};

const Tabs = ({ children, tabOptions }: TabsProps): ReactElement => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  return (
    <div className="fixed-tabs">
      <div className="lp-nav">
        <ul className="nav nav-pills" role="tablist">
          {tabOptions.map((option, index) => (
            <li
              className={`nav-item ${activeTabIndex === index ? 'active' : ''}`}
              key={option.value}
              onClick={() => setActiveTabIndex(index)}
            >
              <div className="nav-link" role="tab" aria-selected={activeTabIndex === index}>
                {option.text}
              </div>
            </li>
          ))}
        </ul>
      </div>
      {children[activeTabIndex]}
    </div>
  );
};

export default Tabs;
