import styled from '@emotion/styled';

export const ContactText = styled.div`
  margin-bottom: 8px;
  cursor: default;
  font-size: 14px;
  font-weight: 300;
  color: #6c7488;
`;

export const SupportLink = styled.a`
  text-decoration: underline;
  color: #6c7488;
  margin-left: 5px;
  line-height: 1.2;
  &:focus {
    outline: none;
  }
`;

export const LegalLink = styled.a`
  text-decoration: underline;
  color: #000;
  margin-right: 5px;
  line-height: 1.2;
  &:focus {
    outline: none;
  }
`;

export const LegalText = styled.div`
  font-size: 15px;
`;
