import * as s from './styles';
import { RadioButtonGroupType } from './types';

const RadioButtonGroup = ({ name, background = '', radios, value, handleChange }: RadioButtonGroupType) => {
  return (
    <s.Wrapper>
      {radios.map((radio, index) => (
        <s.Label key={index} bg={background}>
          <input type="radio" name={name} value={radio.value} checked={radio.value === value} onChange={handleChange} />
          <s.TextRow>
            <s.Text>{radio.text}</s.Text>
            <s.Amount>{radio.amount}</s.Amount>
          </s.TextRow>
          <span className="check-mark" />
        </s.Label>
      ))}
    </s.Wrapper>
  );
};

export default RadioButtonGroup;
