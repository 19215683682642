import { APIResponseType } from 'Components/Payments/types';

type ObjType = {
  [key: number]: number;
};

type SuccessObjType = {
  [key: number]: boolean;
};

const calculateAttemptsCount = (payout: APIResponseType): number[] => {
  if (!payout.charges || payout.charges.entries.length === 0 || payout.state === 'Completed') {
    return [0, 0, 0];
  }

  // key 2, 3 and 4 stand for failed attempts of instalment 2, 3, and 4, respectively
  const obj: ObjType = {
    2: 0,
    3: 0,
    4: 0,
  };

  const successObj: SuccessObjType = {
    2: false,
    3: false,
    4: false,
  };

  for (const entry of payout.charges.entries) {
    if (entry.instalmentNumber === null) {
      continue;
    }

    if (entry.isSuccess === false) {
      ++obj[entry.instalmentNumber];
    }

    if (entry.isSuccess === true) {
      successObj[entry.instalmentNumber] = true;
    }
  }

  const count2 = successObj[2] === true ? 0 : obj[2];
  const count3 = successObj[3] === true ? 0 : obj[3];
  const count4 = successObj[4] === true ? 0 : obj[4];

  return [count2, count3, count4];
};

export default calculateAttemptsCount;
