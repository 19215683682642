import { ReactComponent as Tick } from 'assets/svg/tick.svg';
import confetti from 'canvas-confetti';
import { useEffect } from 'react';

import styled from '@emotion/styled';

const Wrapper = styled.div`
  width: 450px;
  height: 540px;
  position: relative;
  @media (max-width: 492px) {
    width: 100%;
  }
`;

const Title = styled.div`
  margin-top: 34px;
  margin-bottom: 22px;
  font-size: 16px;
  line-height: 20px;
  color: #191e33;
  text-align: center;
`;

const Hr = styled.hr`
  border-top: 1px solid #e0e1ea;
  margin: 0 24px;
`;

const Ticker = styled.div`
  width: 48px;
  height: 48px;
  background: #00844b;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 24px);
  bottom: 252px;
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    fill: #fff;
  }

  .icon {
    width: 100%;
    height: 100%;
  }
`;

const Text = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 208px;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #555c7c;
`;

const PaymentSuccess = () => {
  useEffect(() => {
    confetti({
      particleCount: 300,
      spread: 70,
      origin: { y: 0.6 },
      zIndex: 1070,
    });
  }, []);

  return (
    <Wrapper>
      <Title>Make a payment</Title>
      <Hr />
      <Ticker>
        <Tick className="icon" />
      </Ticker>
      <Text>Payment successful!</Text>
    </Wrapper>
  );
};

export default PaymentSuccess;
