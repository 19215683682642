import styled from '@emotion/styled';

export const Outer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1050;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type InnerType = {
  minHeight: string;
  minWidth: string;
  mobileInnerHeight: string;
};

export const Inner = styled.div<InnerType>`
  background-color: #fff;

  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  position: relative;
  min-width: ${(props) => props.minWidth};
  min-height: ${(props) => props.minHeight};
  max-height: 100%;
  overflow: auto;
  z-index: 1060;

  @media (max-width: 492px) {
    min-width: 100%;
    max-width: 100%;
    height: ${(props) => props.mobileInnerHeight};
  }

  @media only screen and (max-height: 492px) and (orientation: landscape) {
    height: ${(props) => props.mobileInnerHeight};
  }
`;

export const CloseBtn = styled.button`
  margin-left: auto;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  position: absolute;
  right: 20px;
  top: 20px;
`;

export const Fade = styled.div`
  width: 100%;
  height: 100%;
  background-color: #030305;
  opacity: 0.3;
  position: absolute;
`;

export const BtnGroup = styled.div<{ btnPosition: string }>`
  position: absolute;
  right: 0;
  padding: 0 24px;
  bottom: 24px;
  display: flex;
  width: 100%;
  justify-content: ${(props) => props.btnPosition ?? 'flex-end'};

  @media (max-width: 492px) {
    position: relative;
    right: 0;
    bottom: 0;
    height: 88px;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
  }

  @media (max-width: 340px) {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

export const CancelBtn = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--lp-colors-medium-blue-600);
  font-size: 16px;
  line-height: 20px;
  margin-right: 32px;
  cursor: pointer;
`;

export const ConfirmBtn = styled.button<{ isDanger: boolean }>`
  padding: 10px 16px;
  color: ${({ isDanger }) => (isDanger ? '#030305' : '#f8f8fb')};
  font-size: 16px;
  line-height: 20px;
  min-height: 40px;
  background-color: ${({ isDanger }) => (isDanger ? '#FF6E44' : 'var(--lp-colors-medium-blue-600)')};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  &:disabled {
    opacity: 0.65;
    cursor: default;
  }
`;

export const ReturnBackBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--lp-colors-medium-blue-600);
  margin-right: 32px;

  path {
    fill: var(--lp-colors-medium-blue-600);
  }

  @media (max-width: 492px) {
    .arrow-left {
      display: none;
    }
  }
`;

export const ReturnBackText = styled.div`
  font-size: 16px;
  margin-left: 10px;
`;

export const SpinnerWrapper = styled.div`
  margin-left: 4px;
  padding-top: 2px;
  width: 20px;
  height: 20px;
`;
