import { RefObject, useEffect } from 'react';

type Event = MouseEvent | TouchEvent;

function useClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: Event) => void,
  classArray: string[] = [],
) {
  useEffect(() => {
    const listener = (event: Event) => {
      const el = ref?.current;

      const { classList } = event?.target as HTMLElement;

      const skip = classArray.some((c) => classList.contains(c));

      if (!el || el.contains((event?.target as Node) || null) || skip) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, classArray]);
}
export default useClickOutside;
