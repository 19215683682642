export const TabOptions = [
  {
    text: 'Contact us',
    value: 'ContactUs',
  },
  {
    text: 'Legal',
    value: 'Legal',
  },
];
