import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: relative;
  z-index: 100;

  .sidebar-logo {
    max-width: 100%;
  }

  .menu {
    padding-top: 20px;
    z-index: 99;
  }

  .menu-link {
    padding: 0 30px;
    color: #fff;
    text-decoration: none;
    display: block;
    position: relative;
    &.selected:before {
      top: 0;
      left: 0;
      content: '';
      position: absolute;
      width: 3px;
      height: 23px;
      background-color: var(--lp-colors-turquoise-600);
    }
  }

  .arrow-down {
    width: 20px;
    fill: #fff;
    transition: transform 0.3s ease-in-out 0s;
    margin-left: auto;
    margin-right: 30px;

    &.up {
      transform: rotate(180deg);
    }
  }
`;

export const CollapseBtn = styled.button`
  background: none;
  border: none;
  box-shadow: none;
  color: #505bcc;
  font-size: 24px;
  line-height: 40px;
  border-radius: 0;
  outline: none;
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: initial;
  cursor: pointer;
  position: absolute;
  left: 10px;
  margin-top: 4px;
  top: 25px;
  display: none;
  @media (max-width: 960px) {
    display: block;
  }
`;

export const IconBar = styled.span`
  display: block;
  width: 22px;
  height: 1px;
  margin-bottom: 6px;
  background-color: #6c7488;
  position: relative;
`;

type AsideType = {
  open: boolean;
};

export const Aside = styled.aside<AsideType>`
  background-color: #191e33;
  border-right: 1px #e7e7e7 solid;
  width: 240px;
  padding-bottom: 60px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  -webkit-transition: left 0.3s ease;
  transition: left 0.3s ease;
  z-index: 3333;

  @media (max-width: 960px) {
    left: ${(props) => (props.open ? '0' : '-240px')};
  }
`;

export const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 51px;
  width: 100%;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Header = styled.div`
  position: relative;
  z-index: 100;
`;

export const Brand = styled.div`
  padding: 35px 30px 9px;

  @media (max-width: 960px) {
    padding-top: 70px;
  }
`;

export const MenuUl = styled.ul`
  font-size: 17px;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 23px;
`;

export const MenuLi = styled.li`
  padding: 19.3px 0;
  position: relative;
`;

export const ImgText = styled.div`
  padding: 15px 0 13px 1px;
  background-color: var(--lp-colors-turquoise-600);
  color: var(--lp-colors-medium-blue-600);
  font-size: 14px;
  text-align: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  box-sizing: border-box;
  text-transform: uppercase;
`;

type FooterType = {
  open: boolean;
};

export const Footer = styled.footer<FooterType>`
  position: absolute;
  bottom: 0;
  width: 240px;
  padding: 21px 0 30px 29px;
  box-sizing: border-box;
  @media (max-width: 960px) {
    width: 240px;
    left: ${(props) => (props.open ? '0' : '-240px')};
  }
  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const CloseBtn = styled.button`
  display: none;
  @media (max-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    background: #fff;
    position: absolute;
    right: 25px;
    top: 25px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;

    .close-icon {
      width: 10px;
      path {
        fill: #0016d1 !important;
      }
    }
  }
`;

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 0;
  border: 0;
`;

export const NavLinkBtn = styled.button`
  width: 100%;
  display: flex;
  color: #fff;
  text-decoration: none;
  background-color: transparent;
  font-weight: 300;
  font-size: 17px;
  cursor: pointer;
  position: relative;
  outline: none;
  border: none;
  padding: 0;
`;

export const FirstName = styled.div`
  color: #fff;
  word-break: break-word;
`;

type CollapseType = {
  show: boolean;
};

export const Collapse = styled.div<CollapseType>`
  height: ${({ show }) => (show ? '40px' : '0')};
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: height 0.3s ease-in-out 0s;
`;

export const ProfileDropdownMenu = styled.div`
  padding-top: 15px;
`;

export const DropdownItemBtn = styled.button`
  padding: 12px 0;
  width: 100%;
  color: #fff;
  display: block;
  text-decoration: none;
  text-align: left;
  background: transparent;
  border: 0;
  font-size: 16px;
  cursor: pointer;
`;
