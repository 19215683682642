import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  min-height: 343px;

  @media (max-width: 492px) {
    flex-direction: column;
  }
`;

export const OptionWrapper = styled.div`
  padding: 34px 24px;
  @media (max-width: 492px) {
    width: 100%;
  }
`;

export const TitleHr = styled.hr`
  border-top: 1px solid #e0e1ea;
  margin: 22px 0 26px;
`;

export const Title = styled.div`
  color: #191e33;
  font-size: 16px;
  line-height: 20px;
  font-family: 'Geograph Bold';
  text-align: center;
`;

type PaymentWrapperType = {
  isActive: boolean;
};
export const PaymentWrapper = styled.div<PaymentWrapperType>`
  background-color: ${(props) => (props.isActive ? '#FEFBF6' : '#fff')};
  border: 1px solid #e0e1ea;
  border-radius: 4px;
  padding: 12px 16px;
  margin: 0 16px 8px;
  display: flex;
  flex-wrap: wrap;
  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width: 492px) {
    margin-left: 8px;
    margin-right: 8px;
  }
`;

export const PaymentRow1 = styled.div`
  display: flex;
  width: 100%;
`;

export const PaymentRow2 = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const PaymentTitle = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: #191e33;
  letter-spacing: 0.15px;
`;

type TagType = {
  isActive: boolean;
};

export const Tag = styled.div<TagType>`
  border-radius: 16px;
  padding: 4px 8px;
  margin-left: 16px;
  font-weight: 700;
  color: #191e33;
  font-size: 12px;
  line-height: 16px;
  background-color: ${(props) => (props.isActive ? '#F8C079' : '#B4DFEA')};
`;

type ScheduleAmountType = {
  isActive: boolean;
};
export const ScheduleAmount = styled.div<ScheduleAmountType>`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  margin-left: auto;
  color: ${(props) => (props.isActive ? '#B88039' : '#191E33')};
`;

export const Row2Info = styled.div`
  color: #555c7c;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.25px;
`;

export const Description = styled.div`
  width: 400px;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  margin: 24px 0;
  color: #555c7c;
  padding: 0 27px;

  strong {
    font-family: 'Geograph Bold';
    font-weight: 700;
  }

  @media (max-width: 492px) {
    padding: 0px;
    width: 100%;
  }
`;
