import { ReactComponent as Close } from 'assets/svg/close-new.svg';
import { ReactComponent as Error } from 'assets/svg/error.svg';
import { ReactComponent as Tick } from 'assets/svg/tick.svg';
import { MouseEvent, ReactElement } from 'react';

import { CloseBtn, Content, Description, IconContainer, Title, Wrapper } from './styles';

export type MessageProps = {
  title: string;
  description?: string;
  handleClose?(e: MouseEvent<HTMLButtonElement>): void;
  theme?: string;
  success?: boolean;
  hasClose?: boolean;
  className?: string;
};

const Message = ({
  title,
  description = '',
  handleClose,
  theme = '',
  success = true,
  hasClose = true,
  className = '',
}: MessageProps): ReactElement => {
  if (!title) {
    return <></>;
  }

  return (
    <Wrapper className={className} success={success} theme={theme}>
      <IconContainer success={success}>
        {success && <Tick />}
        {!success && <Error />}
      </IconContainer>

      <Content success={success}>
        <Title className="MessageTitle">{title}</Title>
        {description.length > 0 && <Description>{description}</Description>}
      </Content>
      {hasClose && (
        <CloseBtn success={success} onClick={handleClose}>
          <Close />
        </CloseBtn>
      )}
    </Wrapper>
  );
};

export default Message;
