export const getErrorMessage = (error: any): string => {
  const isText = (text: any): boolean => typeof text === 'string' && !!text.length;
  if (isText(error)) return error;
  if (isText(error?.message)) return error.message;
  return 'Unknown error';
};

export const handleResponse = async <T = any>(res: Response): Promise<T> => {
  let body = await res.text();

  if (res.ok) {
    // success response
    return body.length ? JSON.parse(body) : body;
  } else {
    // error response
    let errorMessage = body;
    try {
      // attempt to parse json error
      errorMessage = getErrorMessage(JSON.parse(body));
    } finally {
      // throw error message
      throw Error(errorMessage);
    }
  }
};
