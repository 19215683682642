import { VgsCardError } from 'redux/Constants/vgsAndStripeTypes';
import { toCardError } from 'utils/vault';

import { Vault, handleVaultSubmitResponse } from '@april/lib-ui';

export type Card = {
  number: '{{cardNumber}}';
  cvc: '{{cardCvc}}';
  expiryDate: {
    expiryMonth: '{{expiryMonth}}';
    expiryYear: '{{expiryYear}}';
  };
};

export const VAULT_CARD: Card = {
  number: '{{cardNumber}}',
  cvc: '{{cardCvc}}',
  expiryDate: {
    expiryMonth: '{{expiryMonth}}',
    expiryYear: '{{expiryYear}}',
  },
};

export type CardSource = {
  paymentSourceId: string;
  country: string;
  last4: string;
  brand: 'MasterCard' | 'Visa' | 'American Express' | 'Unknown';
  funding: 'Credit' | 'Debit' | 'Prepaid' | 'Unknown';
  cardBin?: string;
  expMonth: number;
  expYear: number;
  createdAt: string;
  updatedAt: string;
};

export type CreateConsumerCardSource = {
  CreateConsumerCardSource: Card;
};

export type AnyCreatePaymentSourceRequest = CreateConsumerCardSource;

export type ConsumerCustomerCardSource = {
  ConsumerCustomerCardSource: {
    customerId: string;
    merchantId: string;
  } & CardSource;
};

export type AnyCreatePaymentSourceResponse = ConsumerCustomerCardSource;

export const vaultCreatePaymentSource = async <T = AnyCreatePaymentSourceResponse>(
  vault: Vault,
  token: string,
  payload: AnyCreatePaymentSourceRequest,
): Promise<{
  response?: T;
  cardError?: VgsCardError;
}> => {
  const { isValid, errors } = vault.validate();

  if (!isValid) return { cardError: toCardError(errors) };

  const response = await vault
    .submit({
      path: '/sources',
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then<T>(handleVaultSubmitResponse);

  return { response };
};
