type BrandObjType = {
  [key: string]: string;
};

// card brand types from backend, which are different from vgs card types
const BrandObj: BrandObjType = {
  visa: 'Visa',
  Visa: 'Visa',
  amex: 'AMEX',
  'American Express': 'AMEX',
  diners: 'Diners Club',
  discover: 'Discover',
  jcb: 'JCB',
  mastercard: 'MasterCard',
  MasterCard: 'MasterCard',
  unionpay: 'UnionPay',
  unknown: 'Unknown',
  '': '',
};

const getFormattedCardBrand = (brand: string) => BrandObj[brand];

export default getFormattedCardBrand;
