import styled from '@emotion/styled';

export const SuccessMsg = styled.div`
  .MessageTitle {
    font-family: 'Geograph';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #555c7c;
    margin-top: 4px;
  }
  @media (max-width: 492px) {
    margin: 30px 15px 0;
  }
`;

export const Box = styled.div`
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  box-shadow: 0.5px 0.5px 3px rgba(220, 227, 235, 0.5);
  border-radius: 4px;
  padding: 36px 30px 40px;
  @media (max-width: 492px) {
    margin: 24px 15px 0;
  }
`;

export const SectionTitle = styled.h2`
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  color: #191e33;
`;

export const Group = styled.div`
  position: relative;
  padding-right: 209px;
  @media (max-width: 492px) {
    padding: 0;
  }
`;

export const Description = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #555c7c;

  .link {
    margin: 0 4px;
    text-decoration: underline;
    color: #555c7c;
    font-weight: 700;
  }
`;

export const AddCardBtn = styled.button`
  padding: 10px 16px;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;

  background: var(--lp-colors-medium-blue-600);
  height: 40px;
  width: 149px;
  border: none;
  border-radius: 4px;

  display: flex;
  align-items: center;
  text-align: center;
  color: #f8f8fb;
  position: absolute;
  top: 0;
  right: 0;

  @media (max-width: 492px) {
    position: relative;
    margin: 28px auto 0;
  }
`;

export const Split = styled.div`
  border-bottom: 1px solid #e5e5e5;
  margin-top: 35px;
  margin-bottom: 8px;
`;

export const CardsGroup = styled.div`
  display: flex;
  min-height: 104px;
  flex-wrap: wrap;
`;

export const CardListSpinner = styled.div`
  height: 104px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const CardWrapper = styled.div`
  position: relative;
  .card {
    margin-right: 32px;
    margin-top: 32px;
  }
  @media (max-width: 492px) {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    .card {
      margin-right: auto;
      margin-left: auto;
      width: 163px;
    }
  }
`;

export const MobileDeleteLink = styled.button`
  display: none;
  margin: 10px auto 0;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  color: var(--lp-colors-medium-blue-600);
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  border: none;
  outline: none;
  @media (max-width: 492px) {
    display: block;
  }
`;

export const CardCover = styled.div`
  position: absolute;
  width: 163px;
  height: 104px;
  top: 32px;
  background: #555c7c;
  opacity: 0.9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 492px) {
    display: none;
  }
`;

export const DeleteBtn = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  text-decoration: underline;
  outline: none;
`;

export const Empty = styled.div`
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #191e33;
  margin-top: 44px;
  font-weight: 700;
`;

export const ModalWrapper = styled.div`
  padding: 0 24px;
`;

export const ModalTitle = styled.div`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #191e33;
  font-family: 'Geograph Bold';
  margin-top: 34px;
`;

export const ModalSplit = styled.div`
  border-bottom: 1px solid #e5e5e5;
  margin-top: 22px;
  margin-bottom: 24px;
`;

export const ModalContent = styled.div`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #191e33;
  width: 312px;
  @media (max-width: 492px) {
    max-width: 100%;
  }
`;

export const AddCardWrapper = styled.div`
  padding: 0 24px 80px;
  width: 450px;
  @media (max-width: 492px) {
    padding-bottom: 0;
    max-width: 100%;
  }
`;

export const StripeWrapper = styled.div`
  width: 400px;
  padding: 0 20px;

  @media (max-width: 492px) {
    max-width: 100%;
    padding: 0;
  }
`;

export const CardNumber = styled.div`
  position: relative;
  padding: 8px 16px 8px 56px;
  height: 56px;
  border-bottom: 1px solid #e0e1ea;

  .card-number-icon {
    position: absolute;
    left: 18px;
    top: 18px;
  }
`;

export const StripeInput = styled.div`
  height: 40px;
  width: 100%;
  color: #191e33;
  iframe {
    width: 100%;
    height: 100%;
  }
`;

export const Expiry = styled.div`
  position: relative;
  padding: 8px 16px 8px 56px;
  height: 56px;
  border-bottom: 1px solid #e0e1ea;
  width: 45%;
  .expiry-icon {
    position: absolute;
    left: 18px;
    top: 15px;
  }
`;

export const Cvc = styled.div`
  position: relative;
  padding: 8px 16px 8px 56px;
  height: 56px;
  border-bottom: 1px solid #e0e1ea;
  width: 45%;
  .cvc-icon {
    position: absolute;
    left: 18px;
    top: 18px;
  }
`;

export const StripeRow = styled.div`
  display: inline-flex;
  justify-content: space-between;
  margin: auto;
`;

type StripeErrorMsgType = {
  hasError: boolean;
};

export const StripeErrorMsg = styled.div<StripeErrorMsgType>`
  font-size: 11px;
  line-height: 1.1;
  font-weight: 300;
  padding-top: 10px;
  color: rgb(184, 0, 10);
  position: absolute;
  left: 0;
  display: ${(props) => (props.hasError ? 'block' : 'none')};
`;
