import { H2, LpBox } from 'Constants/styles';

import { ContactText, SupportLink } from './styles';

const ContactUs = () => (
  <LpBox>
    <H2>Have a question?</H2>
    <ContactText>
      For assistance with your loan, please get in touch with our support team at
      <SupportLink href="mailto:support@meetapril.com">support@meetapril.com</SupportLink>.
    </ContactText>
  </LpBox>
);

export default ContactUs;
