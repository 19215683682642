import { handleResponse } from './utils';

export const changePayPlanCardPaymentSource = (
  host: string,
  token: string,
  customerId: string,
  payPlanId: string,
  cardPaymentSourceId: string,
): Promise<any> => {
  const url = `${host}/customers/${customerId}/payments/plan/${payPlanId}/payment-source`;
  const payload = {
    cardPaymentSourceId,
  };
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };

  return fetch(url, options).then(handleResponse);
};

export const payAllOfPayPlan = (host: string, token: string, customerId: string, payPlanId: string): Promise<any> => {
  const url = `${host}/customers/${customerId}/payments/plan/${payPlanId}/pay-all`;
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  return fetch(url, options).then(handleResponse);
};
