import { ReactComponent as WarnIcon } from 'assets/svg/warning.svg';
import { MouseEvent, ReactElement } from 'react';
import { toCurrency } from 'utils/currency';
import getDateFormat from 'utils/getDateFormat';

import styled from '@emotion/styled';

import { OverdueBoxType } from './types';

const Wrapper = styled.div`
  background: #fefbf6;
  border: 1px solid #f5ab4c;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 16px 44px;
  position: relative;
  margin-bottom: 20px;
`;

const Title = styled.div`
  color: #7a5526;
  font-weight: 700;
  line-height: 20px;
  font-size: 14px;
  letter-spacing: 0.15px;
  margin-bottom: 4px;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #7a5526;

  .mailto {
    color: inherit;
    text-decoration: underline;
  }
`;

const Warning = styled(WarnIcon)`
  width: 20px;
  height: 20px;
  position: absolute;
  margin-left: -28px;
`;

const UpdatePaymentBtn = styled.button`
  padding: 0;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #7a5526;
  font-weight: 700;
`;

const Strong = styled.strong`
  font-weight: 700;
  font-size: inherit;
  line-height: inherit;
`;

const Underline = styled.span`
  text-decoration: underline;
  color: inherit;
  font-weight: inherit;
`;

const OverdueBox = ({ payout, attemptsCount, toggleModalEditPayment }: OverdueBoxType): ReactElement => {
  if (attemptsCount.length < 3 || attemptsCount.every((c) => c === 0)) {
    return <></>;
  }

  // 1st failed attempt of any instalment
  const type1 =
    (attemptsCount[0] === 1 && attemptsCount[1] === 0 && attemptsCount[2] === 0) ||
    (attemptsCount[1] === 1 && attemptsCount[2] === 0) ||
    attemptsCount[2] === 1;

  // 2nd failed attempt of 2nd instalment
  const type2B = attemptsCount[0] === 2 && attemptsCount[1] === 0 && attemptsCount[2] === 0;

  // 2nd failed attempt of 3rd instalment
  const type2C = attemptsCount[1] === 2 && attemptsCount[2] === 0;

  // 2nd failed attempt of 4th instalment
  const type2D = attemptsCount[2] === 2;

  // 3rd failed attempt of 4th instalment
  const type3D = attemptsCount[2] === 3;

  const handleEditPayment = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    toggleModalEditPayment(e);
  };

  return (
    <Wrapper>
      <Warning />
      <Title>{type1 ? 'Avoid a late fee on your overdue account' : 'Your account is overdue'}</Title>
      {type1 && (
        <Description>
          Your scheduled {toCurrency(payout.schedule[0].amount, payout.currency)} payment was declined.
          <br />
          Please make sure you have available funds when we reattempt this charge on{' '}
          {
            getDateFormat({
              time: payout.nextInstalmentDate ?? '',
              showShortMonth: true,
              showDaySuffix: false,
              showComma: false,
              showYear: false,
            }).date
          }{' '}
          to avoid a $5.00 late fee.
        </Description>
      )}
      {type2B && (
        <Description>
          To get you back on track, we have split your overdue payments and late fees between your upcoming scheduled
          payments.
          <br />
          <Strong>
            Please make sure you have available funds on{' '}
            {
              getDateFormat({
                time: payout.nextInstalmentDate ?? '',
                showShortMonth: true,
                showDaySuffix: false,
                showComma: false,
                showYear: false,
              }).date
            }{' '}
            or <UpdatePaymentBtn onClick={handleEditPayment}>update your payment source</UpdatePaymentBtn> to avoid
            further late fees.
          </Strong>
          <div className="mt-2">
            If you are having trouble meeting your repayments, please{' '}
            <a className="mailto" href="mailto:support@meetapril.com">
              reach out to our team
            </a>{' '}
            to discuss your options.
          </div>
        </Description>
      )}
      {type2C && (
        <Description>
          Your overdue payments and late fees have been added to your last scheduled payment.
          <br />
          <Strong>
            Please make sure you have available funds on{' '}
            {
              getDateFormat({
                time: payout.nextInstalmentDate ?? '',
                showShortMonth: true,
                showDaySuffix: false,
                showComma: false,
                showYear: false,
              }).date
            }{' '}
            or <UpdatePaymentBtn onClick={handleEditPayment}>update your payment source</UpdatePaymentBtn> to avoid
            further late fees.
          </Strong>
          <div className="mt-2">
            If you are having trouble meeting your repayments, please{' '}
            <a className="mailto" href="mailto:support@meetapril.com">
              reach out to our team
            </a>{' '}
            to discuss your options.
          </div>
        </Description>
      )}
      {type2D && (
        <Description>
          Your {toCurrency(payout.planAmountOutstanding ?? 0, payout.currency)} payment was declined again and is now 2
          days overdue.
          <br />
          <Strong>
            Please make sure you have available funds on{' '}
            {
              getDateFormat({
                time: payout.nextInstalmentDate ?? '',
                showShortMonth: true,
                showDaySuffix: false,
                showComma: false,
                showYear: false,
              }).date
            }{' '}
            or <UpdatePaymentBtn onClick={handleEditPayment}>update your payment source</UpdatePaymentBtn> to avoid
            further late fees.
          </Strong>
          <div className="mt-2">
            If you are having trouble meeting your repayments, please{' '}
            <a className="mailto" href="mailto:support@meetapril.com">
              reach out to our team
            </a>{' '}
            to discuss your options.
          </div>
        </Description>
      )}
      {type3D && (
        <Description>
          <div>
            We have been unable to process your final payment and your account is overdue.{' '}
            <Strong>
              Please <Underline>pay {toCurrency(payout.planAmountOutstanding ?? 0, payout.currency)}</Underline> to
              complete your plan.
            </Strong>
          </div>
          <div className="mt-2">
            <Strong>
              Unfortunately, we can’t offer you any further payment plans until you have paid your outstanding balance
              in full.
            </Strong>{' '}
            If you are having trouble meeting your repayments, please{' '}
            <a className="mailto" href="mailto:support@meetapril.com">
              reach out to our team
            </a>{' '}
            to discuss your options.
          </div>
        </Description>
      )}
    </Wrapper>
  );
};

export default OverdueBox;
