import { ReactElement } from 'react';

import { Content, Triangle, Wrapper } from './styles';

type InfoModalType = {
  content: string;
  isMobile?: boolean;
};

const InfoModal = ({ content, isMobile = false }: InfoModalType): ReactElement => {
  if (content.length === 0) {
    return <></>;
  }

  return (
    <Wrapper isMobile={isMobile}>
      <Triangle isMobile={isMobile} />
      <Content>{content}</Content>
    </Wrapper>
  );
};

export default InfoModal;
