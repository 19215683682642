import { ChargeType, ChargesPaymentSourcesType, EntryType, LedgerEntriesType } from 'Components/Payments/types';
import getDateFormat from 'utils/getDateFormat';
import getFormattedCardBrand from 'utils/getFormattedCardBrand';

import { TransactionType } from './types';

const DescriptionsObj = {
  [EntryType.Credit]: '<strong>Plan initiated</strong>',
  [EntryType.LatePaymentFee]: '<strong>Late fee applied</strong>',
  [EntryType.Waiver]: '<strong>Late fee/s waived</strong>',
  [EntryType.PlanRefund]: '<strong>Plan credited</strong>',
  [EntryType.ChargeRefund]: '<strong>Refund</strong>',
  [EntryType.Charge]: '',
};

export const getObjFromLedger = (ith: LedgerEntriesType, totalBalance: number): TransactionType => {
  const { entryType } = ith;
  let { amount } = ith;
  const time = getDateFormat({ time: ith.entryTime, showDaySuffix: false, showComma: false, showYear: true }).date;
  const description = DescriptionsObj[entryType];

  if (
    entryType === EntryType.Credit ||
    entryType === EntryType.LatePaymentFee ||
    entryType === EntryType.ChargeRefund
  ) {
    amount *= -1;
  }

  const balance = totalBalance + amount;
  return {
    time,
    balance,
    description,
    creditAmount: amount > 0 ? amount : null,
    debitAmount: amount < 0 ? amount : null,
  };
};

export const getObjFromCharges = (
  jth: ChargeType,
  totalBalance: number,
  chargesPaymentSources?: ChargesPaymentSourcesType,
): TransactionType => {
  const time = getDateFormat({ time: jth.createdAt, showDaySuffix: false, showComma: false, showYear: true }).date;

  let description = '';
  let balance = 0;
  if (jth.isSuccess === false) {
    description = '<strong>Payment declined</strong>';
    balance = totalBalance;
  } else {
    description =
      jth.instalmentNumber === null ? '<strong>Manual payment</strong>' : '<strong>Scheduled payment</strong>';
    balance = totalBalance + jth.chargedAmount;
  }

  const { brand, last4 } = chargesPaymentSources?.[jth.cardPaymentSourceId] ?? {};
  const formattedBrand = getFormattedCardBrand(brand ?? '') || 'Card';
  description += ` from ${formattedBrand} x-${last4}`;

  return {
    time,
    balance,
    description,
    creditAmount: jth.isSuccess ? jth.chargedAmount : null,
    debitAmount: null,
  };
};
