import styled from '@emotion/styled';

import { Text } from './styles';

export const OrderTextFlex = styled.div`
  display: flex;
`;

export const OrderTextValue = styled.div`
  width: 235px;
  margin-left: auto;
  text-align: left;
  @media (max-width: 768px) {
    width: auto;
  }
`;

export const Text4x = styled(Text)`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const FortnightText = styled(Text)`
  margin-left: 4px;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

export const BalanceText = styled.div`
  color: var(--lp-colors-medium-blue-600);
  font-size: 20px;
  line-height: 28px;
  padding-left: 40px;
  @media (max-width: 768px) {
    padding-left: 0;
  }
`;

type LargeTextType = {
  color: string;
  left?: string;
  smLeft?: string;
  sm?: string;
};

export const LargeText = styled.div<LargeTextType>`
  font-family: Geograph;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: ${(props) => props.color};
  padding-left: ${(props) => props.left ?? '0'};
  @media (max-width: 768px) {
    padding-left: ${(props) => props.smLeft ?? '0'};
    text-align: ${(props) => props.sm ?? null};
  }
`;

type PayAllBtnType = {
  overdue: boolean;
};

export const PayAllBtn = styled.button<PayAllBtnType>`
  background-color: ${(props) => (props.overdue ? '#FF6E44' : 'var(--lp-colors-medium-blue-600)')};
  padding: 10px 16px;
  border: ${(props) => (props.overdue ? '1px solid #FF6E44' : '1px solid var(--lp-colors-medium-blue-600)')};
  border-radius: 4px;
  color: #f8f8fb;
  font-size: 16px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

export const GreyContent = styled.div`
  background-color: #f8f8fb;
  border-radius: 2px;
  padding: 20px 24px;

  @media (max-width: 400px) {
    padding: 20px 16px;
  }
`;

export const GreyRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

type GreyColumnType = {
  width?: string;
};
export const GreyColumn = styled.div<GreyColumnType>`
  text-align: left;
  width: ${(props) => props.width ?? '25%'};
  @media (min-width: 1200px) {
    width: 25%;
  }
  @media (max-width: 1199px) and (min-width: 961px) {
    width: ${(props) => props.width ?? '25%'};
  }
  @media (max-width: 768px) {
    text-align: center;
    width: 50%;
  }
`;

export const CreditsModal = styled.div`
  position: absolute;
  z-index: 100;
  top: -125px;
  left: -62px;
  @media (max-width: 768px) {
    left: -67px;
  }
  @media (max-width: 450px) {
    left: -233px;
    top: -32px;
  }
`;

export const DebitsModal = styled.div`
  position: absolute;
  z-index: 100;
  top: -111px;
  left: -62px;
  @media (max-width: 768px) {
    left: -67px;
  }
`;

export const Hr = styled.div`
  display: none;
  height: 1px;
  width: 100%;
  border-top: 1px solid #e0e1ea;
  margin: 20px 0;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const InfoIcon = styled.div`
  border: 1.33333px solid #191e33;
  border-radius: 50%;
  margin-left: 10px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 768px) {
    margin-left: 5px;
  }
  @media (max-width: 400px) {
    margin-left: 2px;
  }
`;

export const InfoWrapper = styled.div`
  position: relative;
`;

export const PayPlanText = styled.div`
  display: flex;
`;

export const Symbol = styled.div`
  width: 40px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  @media (max-width: 768px) {
    width: 20px;
  }
`;
