import './HeaderTitle.scss';

import React, { ReactElement } from 'react';

export type HeaderTitleType = {
  title: string;
};

const HeaderTitle = ({ title }: HeaderTitleType): ReactElement => (
  <header className="lp-dashboard-top header-title">
    <div className="title-block">
      <div className="row">
        <div className="col-md-12">
          <h1 className="title custom-title">{title}</h1>
        </div>
      </div>
    </div>
  </header>
);

export default HeaderTitle;
