import { APIResponseType, EntryType } from 'Components/Payments/types';

export const getTotalCredits = (payout: APIResponseType): number => {
  const { entries } = payout.ledger;

  let sum = 0;
  entries.forEach(({ entryType, amount }) => {
    if (entryType === EntryType.Charge || entryType === EntryType.PlanRefund || entryType === EntryType.Waiver) {
      sum += amount;
    }
  });
  return sum;
};

export const getTotalDebits = (payout: APIResponseType): number => {
  const { entries } = payout.ledger;

  let sum = 0;
  entries.forEach(({ entryType, amount }) => {
    if (entryType === EntryType.LatePaymentFee || entryType === EntryType.ChargeRefund) {
      sum += amount;
    }
  });

  return sum;
};
