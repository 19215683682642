import styled from '@emotion/styled';

export const LabelText = styled.label`
  font-size: 15px;
  color: #6c7488;
  font-weight: 300;
`;

export const LogoutBtn = styled.button`
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  color: var(--lp-colors-medium-blue-600);
  border: none;
  font-size: 15px;
  font-weight: 300;
  &:hover {
    text-decoration: underline;
  }
`;

export const H2 = styled.h2`
  font-family: 'Geograph', Arial, sans-serif;
  font-size: 18px;
  line-height: 21px;
  color: #000;
  font-weight: 500;
  margin: 0;
`;

export const Wrapper = styled.div`
  background-color: #f8f8fb;
  min-height: 100vh;

  @media (max-width: 570px) {
    .fixed-tabs .lp-nav {
      background-color: #f8f8fb;
    }
  }

  @media (max-width: 768px) {
    .header-title.lp-dashboard-top {
      background-color: #f8f8fb;
    }

    .details-wrapper,
    .notifications-wrapper {
      border-radius: 4px;
      margin: 24px 15px 0;
    }
  }
`;
