declare global {
  interface Window {
    zE?: (action: string, event: string, parameters?: any) => undefined;
    zESettings: any;
  }
}

export const clearZendeskWidget = () => {
  try {
    window.zE?.('messenger', 'logoutUser');
  } catch (error) {
    console.warn(error);
  }
};

export const hideZendeskWidget = () => {
  try {
    window.zE?.('messenger', 'hide');
  } catch (error) {
    console.warn(error);
  }
};

export const showZendeskWidget = () => {
  try {
    window.zE?.('messenger', 'show');
  } catch (error) {
    console.warn(error);
  }
};

export const identifyZendeskUser = (name: string, email: string) => {
  // TODO: https://developer.zendesk.com/api-reference/widget-messaging/web/authentication/#login
  // try {
  //   window.zE?.('messenger', 'loginUser', (callback: (token: string) => void) => {
  //     callback('token');
  //   });
  // } catch (error) {
  //   console.warn(error);
  // }
};

export const configZendeskWidget = () => {
  // TODO: remove
  // window.zE?.('webWidget', 'updateSettings', {
  //   webWidget: {
  //     color: { theme: '#0016D1' },
  //     offset: {
  //       horizontal: '10px',
  //       vertical: '18px',
  //       mobile: {
  //         horizontal: '0',
  //         vertical: '18px',
  //       },
  //     },
  //   },
  // });
};
