// format returned 1st Jan 2021, 10:10

type FormattedTime = {
  date: string;
  time: string;
  formatted: string;
};

type SuffixProps = {
  [key: string]: string;
};

const daySuffixObj: SuffixProps = {
  '1': 'st',
  '2': 'nd',
  '3': 'rd',
  others: 'th',
};

type Props = {
  time: string;
  showFullYear?: boolean;
  showShortMonth?: boolean;
  showDaySuffix?: boolean;
  showHoursMins?: boolean;
  showSeconds?: boolean;
  showComma?: boolean;
  showYear?: boolean;
};

const getDateFormat = ({
  time,
  showFullYear = false,
  showShortMonth = true,
  showDaySuffix = true,
  showSeconds = false,
  showComma = true,
  showYear = true,
}: Props): FormattedTime => {
  const date = new Date(time);

  const fullYear = date.getFullYear().toString();

  let year = '';
  if (showYear) {
    year = showFullYear ? fullYear : `'` + fullYear.substr(-2);
  }
  const monthFormat = showShortMonth ? 'short' : 'long';
  const month = date.toLocaleString(undefined, { month: monthFormat });
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const suffix = daySuffixObj[day.toString()] || daySuffixObj['others'];

  const daySuffix = showDaySuffix ? suffix : '';
  const comma = showComma ? ',' : '';

  const dateResult = `${day}${daySuffix} ${month}${comma} ${year}`.trim();
  const timeResult = `${hours}:${minutes}${showSeconds ? `:${seconds}` : ''}`;

  return {
    date: dateResult,
    time: timeResult,
    formatted: `${dateResult} ${timeResult}`,
  };
};

export default getDateFormat;
