import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding: 16px;
`;

export const Message = styled.div`
  color: #fff;
  background-color: rgb(35, 35, 35);
  border-radius: 8px;
  padding: 12px 16px;
  text-align: center;
`;
