import './Settings.scss';

import Cards from 'Components/Cards/Cards';
import HeaderTile from 'Components/HeaderTitle/HeaderTitle';
import TabContent from 'Components/Tabs/TabContent';
import Tabs from 'Components/Tabs/Tabs';
import { Content } from 'Constants/styles';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { setActivePayment } from 'redux/Actions';

import { TabOptions } from './Constants';
import DetailsContainer from './DetailsContainer';
import Notifications from './Notifications';
import { Wrapper } from './styles';

const Settings = (): ReactElement => {
  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    document.title = 'Settings - April';
  }, []);

  useEffect(() => {
    dispatch(setActivePayment(false, null));
  }, [dispatch]);

  return (
    <Wrapper>
      <HeaderTile title="Settings" />
      <Content className="settings-general">
        <Tabs tabOptions={TabOptions}>
          <TabContent>
            <DetailsContainer />
            <Notifications />
          </TabContent>
          <TabContent>
            <Cards />
          </TabContent>
        </Tabs>
      </Content>
    </Wrapper>
  );
};

export default Settings;
