import {
  GetNewOrganisationCustomerDelegateResponse,
  administerCustomer,
  getNewOrganisationCustomerDelegate,
} from 'APIs/customer';
import { getErrorMessage } from 'APIs/utils';
import Message from 'Components/Message';
import Spinner from 'Components/Spinner/Spinner';
import qs from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoginPending } from 'redux/Actions';
import { clearZendeskWidget } from 'utils/zendesk';

import { ApproveBtn, Wrapper } from './B2BApprove.style';

export type B2BApproveProps = {};

export function B2BApprove(props: B2BApproveProps) {
  const [delegate, setDelegate] = useState<GetNewOrganisationCustomerDelegateResponse['GetNewDelegateResponse'] | null>(
    null,
  );
  const [loading, setLoading] = useState(false);
  const [approved, setApproved] = useState(false);
  const [error, setError] = useState('');

  const dispatch = useDispatch();
  const { ctx } = useMemo(() => qs.parse(window.location.search), []);

  useEffect(() => {
    (async () => {
      dispatch(setLoginPending(true));
      clearZendeskWidget();
      setError('');

      try {
        if (!ctx) throw Error('Invalid token');

        const { GetNewDelegateResponse } = await getNewOrganisationCustomerDelegate(ctx as string);

        setDelegate(GetNewDelegateResponse);
      } catch (error) {
        setError(getErrorMessage(error));
      }
    })();
  }, [ctx, dispatch]);

  const handleApprove = useCallback(async () => {
    if (!delegate || !ctx) return;
    setError('');
    setLoading(true);

    try {
      const { organisationCustomerId, newDelegateEmailAddress } = delegate.payload;

      await administerCustomer(organisationCustomerId, ctx as string, {
        ApproveDelegateAddition: { newDelegateEmailAddress },
      });

      setApproved(true);
    } catch (error) {
      setError(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  }, [ctx, delegate]);

  return (
    <Wrapper>
      <div className="box">
        <h5 className="box-title title-lg">Verify New Payment Delegate</h5>
        {!delegate && !error && (
          <p style={{ display: 'flex', justifyContent: 'center' }}>
            <Spinner color="#0016D1" />
          </p>
        )}
        {!!error && (
          <div style={{ marginTop: 30 }}>
            <Message success={false} title={error} hasClose={false} />
          </div>
        )}
        {!!delegate && (
          <>
            <p>
              A new user is requesting to join your {delegate.merchantDisplayName} business account with the ability to
              make payments and access credit.
            </p>
            <p style={{ wordBreak: 'break-all' }}>
              <b>Name:</b>{' '}
              {`${delegate.payload.givenName ? `${delegate.payload.givenName} ` : ''}${delegate.payload.familyName}`}
              <br />
              <b>Email:</b> {delegate.payload.newDelegateEmailAddress}
            </p>
            <p>
              If you do not recognise this user, please contact{' '}
              <a href="mailto:support@meetapril.com">support@meetapril.com</a>.
            </p>
            <div className="box-footer">
              {approved && <Message success={true} title="Approved." hasClose={false} />}
              <ApproveBtn type="button" isLoading={loading} disabled={loading || approved} onClick={handleApprove}>
                {loading ? <Spinner width="20px" height="20px" borderWidth="2px" /> : 'Approve'}
              </ApproveBtn>
            </div>
          </>
        )}
      </div>
    </Wrapper>
  );
}
