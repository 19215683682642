import HeaderTile from 'Components/HeaderTitle/HeaderTitle';
import TabContent from 'Components/Tabs/TabContent';
import Tabs from 'Components/Tabs/Tabs';
import { Content } from 'Constants/styles';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { setActivePayment } from 'redux/Actions';

import { TabOptions } from './Constants';
import ContactUs from './ContactUs';
import Legal from './Legal';

const Support = () => {
  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    document.title = 'Support - April';
  }, []);

  useEffect(() => {
    dispatch(setActivePayment(false, null));
  }, [dispatch]);

  return (
    <>
      <HeaderTile title="Support" />
      <Content>
        <Tabs tabOptions={TabOptions}>
          <TabContent>
            <ContactUs />
          </TabContent>
          <TabContent>
            <Legal />
          </TabContent>
        </Tabs>
      </Content>
    </>
  );
};

export default Support;
