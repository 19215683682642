import {
  SET_ACTIVE_PAYMENT,
  SET_CONFIG,
  SET_CUSTOMER_ID,
  SET_CUSTOMER_INFO,
  SET_FETCH_TIMESTAMP,
  SET_LOGIN_PENDING,
  SET_PAYMENTS_TAB_ID,
  UPDATE_LOGGED_IN,
} from '../Constants/actionTypes';
import { ActionType, ReduxStateType } from '../Constants/types';

const initialState: ReduxStateType = {
  apiBaseUri: '',
  tenantId: '',
  merchantId: '',
  customerId: '',
  branding: {
    iconUri: '',
    logoAltText: '',
    logoUri: '',
  },
  isLoggedIn: null,
  isLoginPending: false,
  timestamp: 0,
  email: '',
  phoneNumber: '',
  firstName: '',
  lastName: '',
  initials: '',
  showPaymentDetailsPage: false,
  activePayment: null,
  paymentsTabId: 0,
  tcUrl: '',
  merchantTags: [],
};

const reducers = (state: ReduxStateType = initialState, action: ActionType): ReduxStateType => {
  switch (action.type) {
    case SET_CONFIG:
      const { apiBaseUri, tenantId, merchantId, branding, tcUrl, merchantTags } = action.payload;
      return {
        ...state,
        apiBaseUri,
        tenantId,
        merchantId,
        branding,
        tcUrl,
        merchantTags,
      };

    case SET_LOGIN_PENDING:
      const { isLoginPending } = action.payload;
      return {
        ...state,
        isLoginPending,
      };

    case SET_CUSTOMER_ID:
      const { customerId } = action.payload;
      return {
        ...state,
        customerId,
      };

    case UPDATE_LOGGED_IN:
      const { isLoggedIn } = action.payload;
      return {
        ...state,
        isLoggedIn,
      };

    case SET_FETCH_TIMESTAMP:
      return {
        ...state,
        timestamp: action.payload.timestamp,
      };

    case SET_CUSTOMER_INFO:
      const { email, phoneNumber, lastName, firstName } = action.payload;
      let initials = !!firstName && firstName !== '-' ? firstName.charAt(0) : '';
      initials += !!lastName && lastName !== '-' ? lastName.charAt(0) : '';
      if (initials.length === 0) {
        initials = email.charAt(0);
      }
      return {
        ...state,
        email,
        phoneNumber,
        firstName,
        lastName,
        initials,
      };

    case SET_ACTIVE_PAYMENT:
      return {
        ...state,
        showPaymentDetailsPage: action.payload.showPaymentDetailsPage,
        activePayment: action.payload.activePayment,
      };

    case SET_PAYMENTS_TAB_ID:
      return {
        ...state,
        paymentsTabId: action.payload.paymentsTabId,
      };

    default:
      return state;
  }
};

export default reducers;
