import { ReactComponent as Tick } from 'assets/svg/tick.svg';

import styled from '@emotion/styled';

const Wrapper = styled.label`
  display: flex;
`;

const HiddenCheckbox = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
`;

const StyledCheckbox = styled.div<{ checked: boolean }>`
  width: 24px;
  height: 24px;
  background: #fff;
  border: 1px solid #e0e1ea;
  border-radius: 1px;
  transition: all 150ms;

  .icon {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

const Text = styled.div`
  margin-left: 8px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #555c7c;
`;

type CheckboxType = {
  className?: string;
  checked: boolean;
  text: string;
  handleChange(): void;
};

const Checkbox = ({ className = '', checked, handleChange, text }: CheckboxType) => (
  <Wrapper className={className}>
    <HiddenCheckbox type="checkbox" onChange={handleChange} />
    <StyledCheckbox checked={checked}>
      <Tick className="icon" />
    </StyledCheckbox>
    <Text>{text}</Text>
  </Wrapper>
);

export default Checkbox;
