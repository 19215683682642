export const ListHeaders = [
  {
    className: 'item-col-img',
    text: '',
  },
  {
    className: 'custom-col-xsrr',
    text: 'Order',
  },
  {
    className: 'custom-col-xsr',
    text: 'Total',
  },
  {
    className: 'custom-col-xsr',
    text: 'Paid with',
  },
  {
    className: 'custom-col-xsr',
    text: 'Paid to date',
  },
  {
    className: 'custom-col-xsr',
    text: 'Outstanding',
  },
  {
    className: 'custom-col-xsr',
    text: 'Status',
  },
];

export const ACTIVE = 'Active';
export const COMPLETED = 'Completed';
export const IN_DEFAULT = 'InDefault';

export const PaymentsTabs = [
  {
    text: 'All',
    value: '',
  },
  {
    text: 'Active',
    value: ACTIVE,
  },
  {
    text: 'Paid',
    value: COMPLETED,
  },
  {
    text: 'Overdue',
    value: IN_DEFAULT,
  },
];
