import { H2, Label, LpBox, Row } from 'Constants/styles';
import { useSelector } from 'react-redux';
import { ReduxStateType } from 'redux/Constants/types';

import { LegalLink, LegalText } from './styles';

const Legal = () => {
  const tcUrl = useSelector((state: ReduxStateType) => state.tcUrl);

  return (
    <LpBox>
      <H2>Legal resources</H2>
      <Row className="pt-2">
        <div className="col-sm-4">
          <Label>Loan Terms</Label>
        </div>
        <div className="col-sm-8">
          <LegalText>
            <LegalLink href={tcUrl} target="_blank">
              View Terms of Use
            </LegalLink>
            for your loan
          </LegalText>
        </div>
      </Row>
      <Row className="pt-2">
        <div className="col-sm-4">
          <Label>Privacy Policy</Label>
        </div>
        <div className="col-sm-8">
          <LegalText>
            <LegalLink href="https://meetapril.com/privacy/" target="_blank">
              View Privacy Policy
            </LegalLink>
            for your loan
          </LegalText>
        </div>
      </Row>
    </LpBox>
  );
};

export default Legal;
