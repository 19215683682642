import { firebaseApp } from './firebase';
import { handleResponse } from './utils';

export const login = async (tenantId: string, email: string, password: string) => {
  const firebaseAuth = firebaseApp.auth();
  firebaseAuth.tenantId = tenantId;
  await firebaseAuth.setPersistence('session');
  return firebaseAuth.signInWithEmailAndPassword(email, password);
};

export const signInWithCustomToken = async (tenantId: string, token: string, remember: boolean) => {
  const firebaseAuth = firebaseApp.auth();
  firebaseAuth.tenantId = tenantId;
  await firebaseAuth.setPersistence(remember ? 'local' : 'session');
  return firebaseAuth.signInWithCustomToken(token);
};

export const logout = async () => {
  const firebaseAuth = firebaseApp.auth();
  return firebaseAuth.signOut();
};

export interface CustomerOTPRequestRequest {
  merchantId: string;
  emailAddress: string;
}
export interface CustomerOTPRequestResponse {
  phoneNumberlast4: string;
}
export const customerOTPRequest = (
  host: string,
  payload: CustomerOTPRequestRequest,
): Promise<CustomerOTPRequestResponse> =>
  fetch(`${host}/authn/tenants/accounts:customer-otp-request`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  }).then(handleResponse);
export interface CustomerOtpSignInRequest {
  merchantId: string;
  emailAddress: string;
  emailVerificationCode: string;
  phoneVerificationCode: string;
}
export interface CustomerOtpSignInResponse {
  customToken: string;
}
export const customerOtpSignIn = (
  host: string,
  payload: CustomerOtpSignInRequest,
): Promise<CustomerOtpSignInResponse> =>
  fetch(`${host}/authn/tenants/accounts:customer-otp-signin`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  }).then(handleResponse);

export interface CustomerDelegateOtpRequestRequest {
  merchantId: string;
  limepayCustomerId: string;
  delegateEmail: string;
}
export interface CustomerDelegateOtpRequestResponse {
  phoneNumberlast4?: string;
}
export const customerDelegateOtpRequest = (
  host: string,
  payload: CustomerDelegateOtpRequestRequest,
): Promise<CustomerDelegateOtpRequestResponse> =>
  fetch(`${host}/authn/tenants/accounts:customerDelegateOtpRequest`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  }).then(handleResponse);

export interface CustomerDelegateOtpSignInRequest {
  merchantId: string;
  limepayCustomerId: string;
  delegateEmail: string;
  emailVerificationCode: string;
  phoneVerificationCode?: string;
}
export const customerDelegateOtpSignIn = (
  host: string,
  payload: CustomerDelegateOtpSignInRequest,
): Promise<CustomerOtpSignInResponse> =>
  fetch(`${host}/authn/tenants/accounts:customerDelegateOtpSignin`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  }).then(handleResponse);

export interface CustomerDelegateOtpRequestByOrgIdRequest
  extends Omit<CustomerDelegateOtpRequestRequest, 'limepayCustomerId'> {
  orgCustomerId: string;
}
export const customerDelegateOtpRequestByOrgId = (
  host: string,
  payload: CustomerDelegateOtpRequestByOrgIdRequest,
): Promise<CustomerDelegateOtpRequestResponse> =>
  fetch(`${host}/authn/tenants/accounts:customerDelegateOtpRequestByOrgId`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  }).then(handleResponse);

export interface CustomerDelegateOtpSignInByOrgIdRequest
  extends Omit<CustomerDelegateOtpSignInRequest, 'limepayCustomerId'> {
  orgCustomerId: string;
}
export const customerDelegateOtpSignInByOrgId = (
  host: string,
  payload: CustomerDelegateOtpSignInByOrgIdRequest,
): Promise<CustomerOtpSignInResponse> =>
  fetch(`${host}/authn/tenants/accounts:customerDelegateOtpSigninByOrgId`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  }).then(handleResponse);
