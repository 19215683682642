import { getAprilApiHost } from 'utils/env';

import { handleResponse } from './utils';

export type GetNewOrganisationCustomerDelegateResponse = {
  GetNewDelegateResponse: {
    emailAddress: string;
    marketplaceId?: string;
    merchantId: string;
    customerId: string;
    customerDelegateUserId: string;
    payload: {
      organisationCustomerId: string;
      bizRegistryKybId: string;
      approvingDelegateId: string;
      approvingDelegateEmailAddress: string;
      newDelegateEmailAddress: string;
      givenName?: string;
      familyName: string;
    };
    merchantDisplayName: string;
  };
};

export const getNewOrganisationCustomerDelegate = async (
  token: string,
): Promise<GetNewOrganisationCustomerDelegateResponse> =>
  fetch(`${await getAprilApiHost()}/customers/enterprise/identity`, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  }).then<GetNewOrganisationCustomerDelegateResponse>(handleResponse);

export type AdministerCustomerRequest = {
  ApproveDelegateAddition: {
    newDelegateEmailAddress: string;
  };
};

export type AdministerCustomerResponse = {
  ApproveDelegateAdditionResponse: {
    customerDelegateUserId: string;
  };
};

export const administerCustomer = async (
  customerId: string,
  token: string,
  payload: AdministerCustomerRequest,
): Promise<AdministerCustomerResponse> =>
  fetch(`${await getAprilApiHost()}/customers/enterprise/${customerId}`, {
    method: 'PATCH',
    body: JSON.stringify(payload),
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  }).then<AdministerCustomerResponse>(handleResponse);
