import { Currency } from 'utils/currency';

type MerchantOrderDetailsType = {
  internalOrderId: string;
};

type PaymentSourceType = {
  brand: string;
  last4: string;
  cardPaymentSourceId: string;
  expMonth: number;
  expYear: number;
};

type InstalmentPlanType = {
  initialPayment: number;
  instalmentLength: number;
  instalmentFrequency:
    | 'Weekly'
    | 'Fortnightly'
    | 'EveryFourWeeks'
    | 'EverySevenWeeks'
    | 'EveryTenMinutes'
    | 'EveryThirtyDays';
};

export enum EntryType {
  Charge = 'Charge',
  PlanRefund = 'PlanRefund',
  Waiver = 'Waiver',
  Credit = 'Credit',
  LatePaymentFee = 'LatePaymentFee',
  ChargeRefund = 'ChargeRefund',
}

export type LedgerEntriesType = {
  amount: number;
  entryType: EntryType;
  reason: string;
  entryTime: string;
};

type LedgerType = {
  entries: LedgerEntriesType[];
};

export type ScheduleType = {
  at: string;
  amount: number;
};

export type ChargeType = {
  cardPaymentSourceId: string;
  chargeId: string;
  chargedAmount: number;
  createdAt: string;
  instalmentNumber: number | null;
  isSuccess: boolean;
  updatedAt: string;
};

export type ChargesType = {
  entries: ChargeType[];
};

export type ChargesPaymentSourcesType = {
  [key: string]: PaymentSourceType;
};

export type APIResponseType = {
  merchantOrderDetails: MerchantOrderDetailsType;
  paymentSource?: PaymentSourceType;
  state: string;
  payPlanCreatedAt: string;
  payPlanId: string;
  planAmount: number | null;
  planAmountOutstanding: number | null;
  paidToDate: number | null;
  transactionId: string;
  nextInstalment: number | null;
  instalmentPlan: InstalmentPlanType;
  ledger: LedgerType;
  planCompletionPercent: number;
  schedule: ScheduleType[];
  charges: ChargesType;
  nextInstalmentDate: string | null;
  chargesPaymentSources: ChargesPaymentSourcesType;
  currency: Currency;
};

export type ParamsType = {
  merchantName: string;
  id: string | undefined;
  pay: string | undefined;
};
