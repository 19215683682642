import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { ReduxStateType } from 'redux/Constants/types';

import Details from './Details';

const DetailsContainer = (): ReactElement => {
  const { email, phoneNumber, firstName, lastName } = useSelector((state: ReduxStateType) => ({
    email: state.email,
    phoneNumber: state.phoneNumber,
    firstName: state.firstName,
    lastName: state.lastName,
  }));

  return <Details email={email || ''} firstName={firstName} lastName={lastName} phoneNumber={phoneNumber} />;
};

export default DetailsContainer;
