import { H2, LpBox, LpDetails, Row } from 'Constants/styles';
import React, { ReactElement } from 'react';

import { LabelText } from './styles';

export type DetailsProps = {
  email: string;
  phoneNumber: string | null;
  firstName: string | null;
  lastName: string | null;
};

const Details = ({ email, phoneNumber, firstName, lastName }: DetailsProps): ReactElement => (
  <LpBox className="details-wrapper">
    <Row>
      <div className="col-sm-7">
        <H2>Your Details</H2>
      </div>
    </Row>
    <LpDetails>
      <Row className="pt-4">
        <div className="col-sm-3 col-xl-3">
          <LabelText>Name</LabelText>
        </div>
        <div className="col-sm-9 col-xl-9" data-testid="settings-general-name">
          {firstName} {lastName}
        </div>
      </Row>
      <Row className="pt-4">
        <div className="col-sm-3 col-xl-3">
          <LabelText>Email</LabelText>
        </div>
        <div className="col-sm-9 col-xl-9" data-testid="settings-general-email">
          {email}
        </div>
      </Row>
      <Row className="pt-4">
        <div className="col-sm-3 col-xl-3">
          <LabelText>Phone</LabelText>
        </div>
        <div className="col-sm-9 col-xl-9" data-testid="settings-general-phone">
          {phoneNumber || '-'}
        </div>
      </Row>
    </LpDetails>
  </LpBox>
);

export default Details;
