import getDateFormat from 'utils/getDateFormat';

import styled from '@emotion/styled';

import { OverdueInfoType } from './types';

const Wrapper = styled.div`
  background-color: #f8f8fb;
  border-radius: 2px;
  padding: 20px 24px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  font-weight: 400;
  margin: 10px 0 20px;
  @media (max-width: 768px) {
    padding: 12px 16px;
  }
`;

const Heavy = styled.span`
  font-weight: 700;
`;

const Date = styled.span`
  color: #ff6e44;
  margin-left: 4px;
`;

const OverdueInfo = ({ date }: OverdueInfoType) => (
  <Wrapper>
    <Heavy>We will reattempt this charge on</Heavy>
    <Date>{getDateFormat({ time: date, showDaySuffix: false, showComma: false, showYear: false }).date}</Date>

    <div className="mt-2">To avoid a $5.00 late fee, please make sure your card has available funds.</div>
  </Wrapper>
);

export default OverdueInfo;
