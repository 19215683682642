import './BackComponent.scss';

import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left-new.svg';
import React, { MouseEvent, ReactElement } from 'react';

export type BackComponentType = {
  text?: string;
  handleClickBack(e: MouseEvent<HTMLElement>): void;
};

const BackComponent = ({ text = 'BackComponent', handleClickBack }: BackComponentType): ReactElement => (
  <header className="lp-dashboard-top back-component">
    <div className="title-block">
      <div className="row">
        <div className="title custom-title" onClick={handleClickBack}>
          <ArrowLeft className="arrow-left" />
          <span className="title-text">{text}</span>
        </div>
      </div>
    </div>
  </header>
);

export default BackComponent;
