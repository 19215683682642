import styled from '@emotion/styled';

type WrapperType = {
  theme: string;
  success: boolean;
};
export const Wrapper = styled.div<WrapperType>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
  border: ${(props) => (props.success ? '1px solid #bfe0d2' : '1px solid #D3354A')};
  padding: 16px 44px;
  background-color: ${({ theme, success }) => (theme ? theme : success ? '#f8f8fb' : '#FDF5F6')};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  position: relative;
`;

export const IconContainer = styled.div<{ success: boolean }>`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background-color: ${(props) => (props.success ? '#bfe0d2' : 'transparent')};
  border-radius: 50%;
  position: absolute;
  left: 12px;
  top: 16px;
`;

export const Content = styled.div<{ success: boolean }>`
  color: ${(props) => (props.success ? '#555C7C' : '#B32D3F')};
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-family: 'Geograph Bold';
`;

export const Description = styled.div`
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
`;

export const CloseBtn = styled.button<{ success: boolean }>`
  margin-left: auto;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 14px;
  &:focus {
    outline: none;
  }
  path {
    fill: ${(props) => (props.success ? '#525C7C' : '#B32D3F')};
  }
`;
