import getApiErrorMsg from 'utils/getApiErrorMsg';

const handleApiError = async (res: Response) => {
  const text = await res.text();
  let msg = text;
  try {
    msg = getApiErrorMsg(JSON.parse(text));
  } finally {
    throw Error(msg);
  }
};

export default handleApiError;
