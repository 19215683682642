import { APIResponseType } from 'Components/Payments/types';

const checkIsOverdue = (payout: APIResponseType): boolean => {
  if (payout.schedule.length === 0) {
    return false;
  }

  const now = Date.now();
  const firstSchedule = new Date(payout.schedule[0].at).getTime();

  return now > firstSchedule;
};

export default checkIsOverdue;
