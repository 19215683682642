import styled from '@emotion/styled';

type DueTagType = {
  color: string;
};

export const DueTag = styled.div<DueTagType>`
  color: #191e33;
  font-weight: 700;
  font-size: 12px;
  padding: 0 8px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background-color: #f8c079;
  background-color: ${(props) => props.color};
  border-radius: 16px;
  margin-left: 9px;
`;

export const AmountRow = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 20px;
`;

export const CardIcon = styled.div`
  border: 1px solid #dce3eb;
  width: 48px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

export const PayAmount = styled.div`
  font-weight: 700;
  letter-spacing: 0.15px;
`;

export const PersistHr = styled.hr`
  display: block;
  height: 1px;
  width: 100%;
  border-top: 1px solid #e0e1ea;
  margin: 28px 0 40px;
  @media (max-width: 768px) {
    margin: 28px 0;
  }
`;

export const SmallText = styled.div`
  font-family: Geograph;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #555c7c;
`;

export const ShowPaymentsText = styled.span`
  font-family: Geograph;
  font-size: 14px;
  strong {
    font-family: Geograph Bold;
  }
`;

export const ShowPaymentsLink = styled.span`
  font-family: Geograph Bold;
  font-size: 14px;
  color: var(--lp-colors-medium-blue-600);
  text-decoration: underline;
  cursor: pointer;
`;
