import styled from '@emotion/styled';

type WrapperType = {
  isMobile: boolean;
};
export const Wrapper = styled.div<WrapperType>`
  display: flex;
  align-items: center;
  background-color: #323750;
  border-radius: 4px;
  position: relative;
  width: ${(props) => (props.isMobile ? '225px' : '160px')};
`;

export const Content = styled.div`
  padding: 16px;
  color: #fff;
  letter-spacing: 0.5px;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
`;

type TriangleType = {
  isMobile: boolean;
};

export const Triangle = styled.div<TriangleType>`
  width: 16px;
  height: 16px;
  transform: rotate(45deg);
  position: absolute;
  background-color: inherit;
  bottom: ${(props) => (props.isMobile ? 'calc(50% - 8px)' : '-8px')};
  right: ${(props) => (props.isMobile ? '-8px' : 'calc(50% - 8px)')};
`;
