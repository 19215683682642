import { BrandingType } from 'redux/Constants/types';

import { TabType } from './types';

export const getSideBarLinks = (name: string) => [
  {
    label: 'Purchases',
    href: `/${name}/${TabType.Purchases}`,
  },
  {
    label: 'Settings',
    href: `/${name}/${TabType.Settings}`,
  },
  {
    label: 'Support',
    href: `/${name}/${TabType.Support}`,
  },
];

export const defaultBranding: BrandingType = {
  iconUri: '',
  logoAltText: '',
  logoUri: '',
};
