import BackComponent from 'Components/BackComponent/BackComponent';
import HeaderTile from 'Components/HeaderTitle/HeaderTitle';
import React, { MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import { setActivePayment } from 'redux/Actions';
import { ReduxStateType } from 'redux/Constants/types';

import OrderDetails from './OrderDetails';
import { Content, HeaderStatus, HeaderWrapper, Wrapper } from './PurchaseDetails.styles';
import calculateAttemptsCount from './calculateAttemptsCount';
import checkIsOverdue from './checkIsOverdue';
import { ParamsType } from './types';

const PurchaseDetails = () => {
  const [isOverdue, setIsOverdue] = useState<boolean>(false);
  const [tagText, setTagText] = useState<string>('');
  const [tagColor, setTagColor] = useState<string>('');
  const purchase = useSelector((state: ReduxStateType) => state.activePayment);
  const dispatch: Dispatch<any> = useDispatch();

  /* we need to show different overdue info after different failed attempts
   * 1st element is for 2nd instalment failed attempts
   * 2nd element is for 3rd instalment failed attempts
   * 3rd element is for 4th instalment failed attempts
   */
  const [attemptsCount, setAttemptsCount] = useState<number[]>([0, 0, 0]);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const history = useHistory();

  const params: ParamsType = useParams();

  useEffect(() => {
    if (!purchase) {
      return;
    }

    const counts = calculateAttemptsCount(purchase);
    // when 4th instalment has 3 failed attempts, schedule array will be empty
    const is = checkIsOverdue(purchase) || counts[2] > 2;

    if (is) {
      setTagText('Overdue');
      setTagColor('#FF9273');
    } else {
      const isCompleted = purchase.state === 'Completed';
      setTagText(isCompleted ? 'Paid' : purchase.state);
      setTagColor(isCompleted ? '#B4DFEA' : '#F8C079');
    }

    setIsOverdue(is);
    setAttemptsCount(counts);
  }, [purchase]);

  const handleClickBack = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    history.push(`/${params.merchantName}/purchases`);
    dispatch(setActivePayment(false, null));
  };

  if (!purchase) {
    return <></>;
  }

  return (
    <Wrapper>
      {isMobile && <BackComponent text="Back to purchases" handleClickBack={handleClickBack} />}
      <HeaderWrapper>
        <HeaderTile title={`Order # ${purchase.merchantOrderDetails.internalOrderId}`} />
        <HeaderStatus color={tagColor}>{tagText}</HeaderStatus>
      </HeaderWrapper>
      {!isMobile && <BackComponent text="Back to purchases" handleClickBack={handleClickBack} />}
      <Content>
        <OrderDetails attemptsCount={attemptsCount} payout={purchase} isOverdue={isOverdue} />
      </Content>
    </Wrapper>
  );
};

export default PurchaseDetails;
