import styled from '@emotion/styled';

export const ApproveBtn = styled.button<{ isLoading: boolean }>`
  cursor: pointer;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;

  border-radius: 4px;
  font-size: 16px;
  width: 100%;

  padding: 13px 38px;
  color: #fff;
  background-color: var(--lp-colors-medium-blue-600);
  border: 1px solid var(--lp-colors-medium-blue-600);
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background-color: ${({ isLoading }) => (isLoading ? 'var(--lp-colors-medium-blue-600)' : '#f8f8fb')};
    border: ${({ isLoading }) => (isLoading ? 'var(--lp-colors-medium-blue-600)' : '#f8f8fb')};
    opacity: ${({ isLoading }) => (isLoading ? '0.6' : '1')};
    color: #b3b8cc;
    cursor: default;
    & > * > * {
      fill: #b3b8cc;
    }
  }
`;

export const Wrapper = styled('div')`
  min-height: 100vh;
  padding-top: 80px;
  color: #555c7c;
  background-color: #f6f6fa;

  b {
    color: #000;
  }

  .box {
    margin: 0 auto;
    display: block;
    max-width: 412px;
    border-radius: 4px;
    padding: 24px;
    background-color: #ffffff;
    font-size: 16px;
    line-height: 24px;
  }

  .box-title {
    font-size: 20px;
    margin: 0;
    color: #191e33;
    font-family: 'Geograph', Arial, sans-serif;
  }

  .box-footer {
    flex: 1 0 auto;
  }

  @media (max-width: 768px) {
    padding-top: 30px;

    .box {
      padding: 12px;
    }
  }
`;
