import { LpDetails, Row } from 'Constants/styles';
import { ReactComponent as DinersClubIcon } from 'assets/cards/Diners_Club.svg';
import { ReactComponent as JCBIcon } from 'assets/cards/JCB.svg';
import { ReactComponent as UnionPayIcon } from 'assets/cards/UnionPay.svg';
import { ReactComponent as AmexIcon } from 'assets/cards/amex.svg';
import { ReactComponent as DiscoverIcon } from 'assets/cards/discover.svg';
import { ReactComponent as MastercardIcon } from 'assets/cards/mastercard.svg';
import { ReactComponent as VisaIcon } from 'assets/cards/visa.svg';
import { Fragment, MouseEvent, ReactElement, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { toCurrency } from 'utils/currency';
import getDateFormat from 'utils/getDateFormat';

import OverdueInfo from './OverdueInfo';
import {
  AmountRow,
  CardIcon,
  DueTag,
  PayAmount,
  PersistHr,
  ShowPaymentsLink,
  ShowPaymentsText,
  SmallText,
} from './PayPlanDetails.styles';
import showDueSoon from './showDueSoon';
import { Box, Flex, SectionHeader, Text, UpdatePaymentBtn } from './styles';
import { PayPlanDetailsType } from './types';

const PayPlanDetails = ({ payout, isOverdue, toggleModalEditPayment }: PayPlanDetailsType): ReactElement => {
  const paymentSourceMobile = useMediaQuery({ query: '(max-width: 400px)' });
  const [showPayouts, setShowPayouts] = useState(false);

  if (payout.schedule.length === 0) {
    return <></>;
  }

  const payouts = payout.schedule;
  const maxPayoutsShown = payout.schedule.length > 10 ? 10 : payout.schedule.length;

  const handleShowPayouts = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowPayouts(!showPayouts);
  };

  return (
    <Box className="mt-4">
      <LpDetails>
        <SectionHeader>Pay plan details</SectionHeader>
        <Row className="mt-4">
          <div className="col-12 col-sm-4">
            <Text>Scheduled payments</Text>
          </div>
          <div className="col-12 col-sm-8 mt-sm-0 mt-4">
            {payouts.slice(0, showPayouts ? payouts.length : maxPayoutsShown).map((s, index) => (
              <Fragment key={index}>
                <AmountRow
                  className={`${(isOverdue && index === 0) || index === payout.schedule.length - 1 ? '' : 'mb-4'}`}
                >
                  <PayAmount data-testid="order-detail-payamount">{toCurrency(s.amount, payout.currency)}</PayAmount>
                  <div className="ml-1 mr-1">due</div>
                  {
                    getDateFormat({
                      time: s.at,
                      showShortMonth: true,
                      showDaySuffix: false,
                      showComma: false,
                      showYear: true,
                    }).date
                  }
                  {index === 0 && showDueSoon(s.at) && <DueTag color="#f8c079">Due soon</DueTag>}
                  {index === 0 && isOverdue && <DueTag color="#ff9273">Overdue</DueTag>}
                </AmountRow>
                {index === 0 && isOverdue && <OverdueInfo date={payout.nextInstalmentDate ?? ''} />}
              </Fragment>
            ))}
            <div className="mt-4">
              <ShowPaymentsText>
                Showing <strong>1-{showPayouts ? payouts.length : maxPayoutsShown}</strong> of {payouts.length}{' '}
                payments.{' '}
                {payouts.length > 10 && (
                  <ShowPaymentsLink onClick={handleShowPayouts}>Show {showPayouts ? 'less' : 'all'}</ShowPaymentsLink>
                )}
              </ShowPaymentsText>
            </div>
          </div>
        </Row>
        <PersistHr />
        <Row>
          <div className="col-12 col-sm-4">
            <Text>Payment source</Text>
          </div>
          <div className="col-12 col-sm-8 mt-2 mt-sm-0">
            <SmallText>
              Scheduled payments {!paymentSourceMobile && <Fragment>will be</Fragment>} automatically charged to:
            </SmallText>
          </div>
        </Row>
        <Row className="mt-3">
          <div className="col-12 col-sm-4"></div>
          <div className="col-12 col-sm-8">
            <Flex>
              {(payout.paymentSource?.brand === 'visa' || payout.paymentSource?.brand === 'Visa') && (
                <CardIcon>
                  <VisaIcon />
                </CardIcon>
              )}
              {(payout.paymentSource?.brand === 'mastercard' || payout.paymentSource?.brand === 'MasterCard') && (
                <CardIcon>
                  <MastercardIcon />
                </CardIcon>
              )}
              {(payout.paymentSource?.brand === 'amex' || payout.paymentSource?.brand === 'American Express') && (
                <CardIcon>
                  <AmexIcon />
                </CardIcon>
              )}
              {payout.paymentSource?.brand === 'diners' && (
                <CardIcon>
                  <DinersClubIcon />
                </CardIcon>
              )}
              {payout.paymentSource?.brand === 'discover' && (
                <CardIcon>
                  <DiscoverIcon />
                </CardIcon>
              )}
              {payout.paymentSource?.brand === 'jcb' && (
                <CardIcon>
                  <JCBIcon />
                </CardIcon>
              )}
              {payout.paymentSource?.brand === 'unionpay' && (
                <CardIcon>
                  <UnionPayIcon />
                </CardIcon>
              )}
              {!!payout.paymentSource && (
                <Text data-testid="order-detail-paymentsource-last4">*{payout.paymentSource.last4}</Text>
              )}
              <UpdatePaymentBtn onClick={toggleModalEditPayment}>Update</UpdatePaymentBtn>
            </Flex>
          </div>
        </Row>
      </LpDetails>
    </Box>
  );
};

export default PayPlanDetails;
