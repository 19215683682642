import { firebaseApp } from 'APIs/firebase';

const getCurrentUserToken = async (): Promise<string> => {
  const currentUser = firebaseApp.auth().currentUser;

  if (!currentUser) throw Error();

  const { token } = await currentUser.getIdTokenResult();
  return token;
};

export default getCurrentUserToken;
